<template>
    <v-container class="extra-padded-container">
        <v-row>
            <v-col cols="12">
                <div class="flex-space-between">
                    <h1>USER MESSAGES</h1>
                    <!-- Dropdown for message type filtering -->


                    <span style="width: 250px;">
                        <v-select
                            v-model="messageType"
                            :items="messageTypes"
                            item-text="text"
                            item-value="value"
                        
                            dense
                            outlined
                            return-object
                        ></v-select>
                    </span>

                </div>

                    <div v-if="!displayNoMessagesMsg">
                        <v-row>
                            <v-col v-for="message in messages" :key="message.id" cols="12">
                                <v-card class="mx-auto my-2" style="padding: 20px;">
                                    <v-card-title>
                                        <div>
                                            <div><strong>Name:</strong> {{ message.name }}</div>
                                            <div><strong>Email:</strong> {{ message.email }}</div>
                                        </div>
                                    </v-card-title>
                                    <v-card-subtitle class="pt-2 pb-1">
                                        <strong>Date & Time: {{ getDateTime(message.datetime) }}</strong><br>
                                        <strong>Message:</strong>
                                    </v-card-subtitle>
                                    <v-card-text class="whitespace-pre-wrap">
                                        {{ message.message }}
                                    </v-card-text>
                                    <v-card-actions class="justify-end">
                                        <v-btn style="margin-right: 10px;" color="red" @click="deleteMessage(message.id)">Delete</v-btn>
                                        <v-btn v-if="message.responded == false" color="green"  @click="markAsResponded(message.id)">Responded</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="d-flex justify-end mt-5">
                                <v-pagination v-model="page" :length="pageCount" @input="getMessages"></v-pagination>
                            </v-col>
                        </v-row>
                    </div>

                    <div v-else>
                        You have no messages...
                    </div>




            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { getUserMessages, deleteUserMessage, markMessageAsResponded } from '@/api.js';

export default {
    data() {
        return {
            adminEmail: "",
            page: 1,
            pageSize: 10,
            pageCount: 0,
            messages: [],
            messageType: { value: 'PENDING_RESPONSE', text: 'Pending Response' },
            messageTypes: [
                { value: 'PENDING_RESPONSE', text: 'Pending Response' },
                { value: 'RESPONDED', text: 'Already Responded To' }
            ],
            displayNoMessagesMsg: false
        }
    },
    mounted() {

        this.adminEmail = this.$store.state.userEmail;

        this.getMessages();
    },
    watch: {
        messageType: function() {
            this.relaodMessages();
        }
    },
    methods: {

        getDateTime(datetime) {
            try {
                // Split the datetime string by the space to separate date from time
                let [date, time] = datetime.split(" ");
                
                // Check if both date and time parts exist
                if (!date || !time) return "N/A";
                
                // Further split the date by "-" to get individual components
                let [year, month, day] = date.split("-");
                
                // Split the time by ":" and remove the seconds and milliseconds part
                let [hour, minute] = time.split(":");
                
                // Reformat the date and time in DD:MM:YYYY HH:MM format
                return `${day}/${month}/${year} ${hour}:${minute}`;
            } catch {
                // In case of any parsing errors, return "N/A"
                return "N/A";
            }
        },
        relaodMessages() {
            this.page = 1;
            this.pageCount = 0;
            this.getMessages();
        },
        nextPage() {
            this.page += 1;
            this.getMessages();
        },
        async getMessages() {
            try {

                const response = await getUserMessages({ 
                    admin_email: this.adminEmail, 
                    page: this.page, 
                    page_size: this.pageSize,
                    message_type: this.messageType.value
                });

                if (!response) {
                    console.error("Received no or null response from API.");
                    this.messages = []; 
                    this.pageCount = 0; 
                    return; 
                }

                this.messages = response.messages;

                // if(this.messages == undefined || messsages.length == 0) {
                //     this.displayNoMessagesMsg = true;
                //     alert(1)
                // } else {
                //     this.displayNoMessagesMsg = false;
                // }

                this.pageCount = Math.ceil(response.total / this.pageSize);
            } catch (error) {
                console.error("Failed to fetch messages:", error);
            }
        },
        async deleteMessage(messageId) {
            const payload = {
                "message_id": messageId,
                "admin_email": this.adminEmail
            }

            await deleteUserMessage(payload);
            this.$notify({
                group: 'dark',
                title: 'Deleting Message!',
                text: '',
                duration: 5000
            });
            this.relaodMessages();

        },
        async markAsResponded(messageId) {
            const payload = {
                "message_id": messageId,
                "admin_email": this.adminEmail
            }

            await markMessageAsResponded(payload);

            this.$notify({
                group: 'dark',
                title: 'Marking Message as Responded!',
                text: '',
                duration: 5000
            });
            this.relaodMessages();
        }
    },
}
</script>



<style>

    .whitespace-pre-wrap {
        white-space: pre-wrap;
    }

    .message-type-dropdown {
        
    }

</style>
  