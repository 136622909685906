<template>
    <v-container class="extra-padded-container">

        <v-row>
            <v-col cols="12">
                <h1>ADMIN DASH</h1>
            </v-col>
        </v-row>

        <v-row>
            <!-- View Customer Messages Card -->
            <v-col cols="12" md="4">
                <v-card class="mx-auto">
                    <v-card-title>View Messages</v-card-title>
                    <v-card-text>
                        Check and respond to incoming messages from customers. Stay updated with their queries.
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" text @click="goToMessages">Proceed</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <!-- Manage Users Card -->
            <v-col cols="12" md="4">
                <v-card class="mx-auto">
                    <v-card-title>Manage Users</v-card-title>
                    <v-card-text>
                        Add, remove, or modify user profiles. Manage access rights and roles within the application.
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" text @click="manageUsers">Proceed</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <!-- New View Analytics Card -->
            <v-col cols="12" md="4">
                <v-card class="mx-auto">
                    <v-card-title>View Analytics</v-card-title>
                    <v-card-text>
                        Access and analyze the application data. Review usage statistics, growth metrics, and more.
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" text @click="viewAnalytics">Proceed</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>




        <v-row>
            <!-- Update Exam-->
            <v-col cols="12" md="4">
                <v-card class="mx-auto">
                    <v-card-title>Update Packet</v-card-title>
                    <v-card-text>
                        Modify questions and answers for existing rapid exams.
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" text @click="goToMessages">Proceed</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <!-- Create Exam-->
            <v-col cols="12" md="4">
                <v-card class="mx-auto">
                    <v-card-title>Create Packet</v-card-title>
                    <v-card-text>
                        Create new rapid exams with an awesome user interface!
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" text @click="manageUsers">Proceed</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <!-- Subscriptions -->
            <v-col cols="12" md="4">
                <v-card class="mx-auto">
                    <v-card-title>Subscriptions</v-card-title>
                    <v-card-text>
                        Update pricing, add new products and enrol institutions.
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" text @click="viewAnalytics">Proceed</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
export default {
    methods: {
        goToMessages() {
            window.location.href = "/admin/messages-page";
        },
        manageUsers() {
            // Logic to navigate to the user management page
        },
        viewAnalytics() {
            // Logic to navigate to the analytics page
        }
    }
}
</script>
