<template>

    <div v-if="isLoading">
      <div class="loader-container">
        <div class="spinner"></div>
        <div class="loading-text">LOADING...</div>
      </div>
    </div>

    <v-container v-else class="extra-padded-container">
      <v-data-table
        :headers="headers"
        :items="rapids"
        class="elevation-1 custom-table"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td style="text-align: center; border: none; padding-top:7px; padding-bottom:7px;">
              {{ item.packet }}
            </td>
            <td style="text-align: center; border: none; padding-top:7px; padding-bottom:7px">
              <v-icon v-if="item.status === 'COMPLETE' || item.status === 'IN_PROGRESS'">mdi-check</v-icon>
            </td>
            <td style="text-align: center; border: none; padding-top:7px; padding-bottom:7px">{{ getScore(item) }}</td>
            <td style="text-align: center; border: none; padding-top:7px; padding-bottom:7px">
              <div class="rad-btn-container" style="width:100%; max-width:600px">

        
                <v-btn @click="startExamOrReview(item)" class="mt-1 mb-3 start-exam-btn rad-btn">
                    {{ getProgressLabel(item.status) }}
                </v-btn>



              </div>
            </td>
            <td style="text-align: center; border: none; padding-top:7px; padding-bottom:7px">
              <v-btn v-if="item.status === 'COMPLETE' || item.status === 'IN_PROGRESS'" icon @click="resetExam(item)">
                <v-icon>mdi-reload</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-container>
  </template>

  
  <script>
  import { getActiveSubscriptions, getActivePromos, startNewExam } from '@/api.js';
  import $ from 'jquery';
  export default {
    data() {
      return {
        isLoading: true,
        rapids: [],
        headers: [
          { text: 'Packet', align: 'center', value: 'packet' },
          { text: 'Attempt', align: 'center', value: 'attempt' },
          { text: 'Score', align: 'center', value: 'score' },
          { text: 'Progress', align: 'center', value: 'progress', sortable: false },
          { text: 'Restart', align: 'center', value: 'reset', sortable: false }
        ]
      }
    },
    watch: {
      isLoading() {
        if(this.isLoading==false) {
          this.$nextTick(()=>{
            this.removeTableBorders();
            this.resizeHeaders();
          })
        }
      }
    },
    async mounted() {

      let subscriptions = await getActiveSubscriptions(this.$store.state.firebaseUserId);
      
      this.rapids = subscriptions.map(sub => ({
        ...sub,
        progress: this.getProgressLabel(sub.status)
      }));

      let freebies = await getActivePromos(this.$store.state.userEmail);
      if(this.rapids.length == 1) {
        this.rapids = freebies.map(sub => ({
          ...sub, 
          progress: this.getProgressLabel(sub.status)
        }))
      }

      this.isLoading = false;
    },
    methods: {

      getScore(item) {
        let totalCorrectAnswers = item.total_correct_answers;
        let totalIncorrectAnswers = item.total_incorrect_answers;
        if(totalCorrectAnswers + totalIncorrectAnswers == 0) {
          return "N/A";
        } else {
          return totalCorrectAnswers + " / " + (totalIncorrectAnswers + totalCorrectAnswers);
        }
      },
      async startExamOrReview(rapid) {
        if(rapid.status == "NOT_STARTED") {
            this.$notify({
                group: 'dark',
                title: 'Preparing Exam...',
                text: '',
                duration: 5000
            });
            await startNewExam(this.$store.state.firebaseUserId, rapid.packet);
            const href=`/rapid-exam?packet=${rapid.packet}&newAttempt=true`;
            // this.$router.push(href);
            window.location.href = href;
        } else if(rapid.status == "COMPLETE") {
          const href=`/review-results-page?packet=${rapid.packet}`;
            // this.$router.push(href);
            window.location.href = href;
        } else {
            const href=`/rapid-exam?packet=${rapid.packet}`;
            // this.$router.push(href);
            window.location.href = href;
        }
      },
      async resetExam(rapid) {
        this.$notify({
          group: 'dark',
          title: 'Preparing Exam...',
          text: '',
          duration: 5000
        });
        await startNewExam(this.$store.state.firebaseUserId, rapid.packet);
        const href=`/rapid-exam?packet=${rapid.packet}&newAttempt=true`;
        window.location.href = href;
      },
      getProgressLabel(status) {
        switch (status) {
          case 'IN_PROGRESS':
            return 'CONTINUE';
          case 'COMPLETE':
            return 'REVIEW';
          case 'NOT_STARTED':
          default:
            return 'START';
        }
      },
      removeTableBorders() {
        this.$nextTick(() => { // Ensure the DOM has updated
            $('.elevation-1 .v-data-table__wrapper table thead th').css('border', 'none');
            $('.elevation-1 th').css('padding-bottom', '40px');

            $('.elevation-1 .v-data-footer').css('border', 'none');
            $('.elevation-1 .v-data-footer').css('margin-top', '20px');
        });
      },
      resizeHeaders() {
        $("th").css("font-size", "20px");
      }
    }
  }
  </script>
  
  
  <style scoped>
    .extra-padded-container {
      padding: 20px;
    }
    .elevation-1 {
      border-radius: 30px;
      padding: 50px;
      max-width: 1000px!important;
      margin: 0 auto;
    }
    .elevation-1 .v-data-table .v-data-table__wrapper table {
      border-collapse: separate;
      border-spacing: 0;
    }
    .elevation-1 .v-data-table .v-data-table__wrapper table td,
    .elevation-1 .v-data-table .v-data-table__wrapper table th {
      border: none!important;  /* Removes the borders */
    }
    td {
      font-size:20px!important;
    }
    .rad-btn:hover {
      background-color:rgba(253,253,253,0.93)!important;
      border: 2px solid rgba(253,253,253,0.94)!important;
      transition: all ease-in-out 0.3s;
      color:black!important;
    }

    .start-exam-btn {
      width:110px;
    }


  </style>
  