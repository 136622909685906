<template>
    <v-container class="pa-8">
      <h1 class="text-h1 mb-5 text-center page-heading">Educational Images</h1>
      <p class="intro-text">
        Step into the world of radiology with our free collection of educational images, designed to enhance your learning experience. 
        Whether you're just starting out or looking to refine your skills, these images provide a vital resource for understanding 
        anatomy and pathology through our illustrated images. Click a region and explore at your own pace! 
      </p>

      <v-row dense>
        <v-col
          v-for="(item, index) in items"
          :key="index"
          cols="12"
          sm="6"
          md="3"
        >
          <v-card
            class="mx-auto mb-4"
            height="100"
            @click="cardClicked(item)"
          >
            <v-card-text class="d-flex justify-center align-center text-body-1 font-weight-bold pa-4" style="height: 100%;">
              {{ item }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    data() {
      return {
        items: [
          'Face', 'Cervical', 'Shoulder', 'Elbow',
          'Hand', 'Chest', 'Abdomen', 'Pelvis',
          'Knee', 'Ankle', 'Salter Harris', 'Tips'
        ]
      }
    },
    methods: {
        cardClicked(item) {
            console.log(`Clicked on ${item}`)
            window.location.href = "/images?region=" + item.toUpperCase();
        }
    }
  }
  </script>
  
  <style scoped>
  .v-card {
    cursor: pointer;
    transition: transform 0.2s;
    margin-right:15px!important;
  }


  .v-card__text  {
    font-size:24px!important;
    color:white!important;
  }
  
  .v-card:hover {
    background-color:rgba(250,250,250,0.55);
    .v-card__text  {
        color:black!important;
    }
  }
  
  .v-container {
    max-width: 1000px;
  }

  .page-heading {
    margin-bottom:50px!important;
    font-size: 60px!important;
  }

  .intro-text {
    margin-bottom:50px;
  }
  </style>