<template>
  <v-form @submit.prevent="onSubmit">
    <v-container class="extra-padded-container">
      <!-- Title Row -->
      <v-row>
        <v-col cols="12" sm="8" offset-sm="2">
          <h1 ref="loginTitle">Login to Your Account</h1>
        </v-col>
      </v-row>

      <!-- Email Address Row -->
      <v-row>
        <v-col cols="12" sm="8" offset-sm="2">
          <v-text-field
            label="Email Address"
            v-model="email"
            type="email"
            ref="emailInput"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- Password Row -->
      <v-row>
        <v-col cols="12" sm="8" offset-sm="2">
          <v-text-field
            label="Password"
            v-model="password"
            type="password"
            ref="passwordInput"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- Submit Button Row -->
      <v-row>
        <v-col cols="12" sm="8" offset-sm="2">
          <v-btn type="submit" color="white" style="color:black" block ref="loginBtn">Login</v-btn>
        </v-col>
      </v-row>

      <v-row v-if="showError">
        <v-col cols="12" sm="8" offset-sm="2">
            <v-alert 
                style="background-color: rgba(10,10,10,0.9)!important; border: 1px solid rgba(255,255,255,0.7)!important" 
                class="invalid-credentials-warning" 
                type="error" 
                dismissible 
                @input="showError = false"> <!-- This will hide the alert when dismissed -->
                Failed to login. Invalid credentials
            </v-alert>
        </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="8" offset-sm="2" class="forgot-pass-row">
        Forgot your password? Click <a class="custom-link" href="/forgot-password">here</a> to reset it.<br>
        Don't have an account? Click <a class="custom-link" href="/signup">here</a> to signup.
      </v-col>
    </v-row>

    </v-container>
  </v-form>
</template>


<script>
import { submitUserToDB, addUserEmail } from '@/api.js';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebaseConfig';
// import axios from 'axios';

export default {
    name: 'LoginPage',
    layout: 'radbytes',
    data() {
      return {
          email: '',
          password: '',
          errorMessage: '',
          showError: false // Controls the visibility of the error alert
      };
    },
    methods: {
        async onSubmit() {
          this.showError = false; // Reset the error visibility on each submission
          signInWithEmailAndPassword(auth, this.email, this.password)
          .then(async (userCredential) => {
              // Signed in
              console.log('User logged in:', userCredential.user); 

              const firebaseUserID = userCredential.user.uid;
              const email = userCredential.user.email;

              try {
                await addUserEmail(firebaseUserID, email)
              } catch {
                console.log("");
              }

              submitUserToDB(firebaseUserID, email);
              
              this.$router.push({ name: 'rapids' });
              this.errorMessage = ''; // Clear any previous errors
          })
          .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              console.error('Error logging in:', errorCode, errorMessage);
              this.errorMessage = "Failed to log in: " + errorMessage;
              this.showError = true; // Show the error alert
          });
        },

        
    },
    mounted: function() {
        setTimeout(() => {
            const els = document.querySelectorAll("input:-webkit-autofill")
            els.forEach((el) => {
                const label = el.parentElement.querySelector("label")
                label.classList.add("v-label--active")
            })
        }, 200)
    }
}
</script>


<style>

 .forgot-pass-row {
  font-size: 18px;
 }

 .custom-link {
  color:rgb(176, 176, 250)!important;
 }

</style>


<!-- <label for="input-17" class="v-label v-label--active theme--dark" style="left: 0px; right: auto; position: absolute;">Email Address</label> -->
<!-- <label for="input-17" class="v-label theme--dark" style="left: 0px; right: auto; position: absolute;">Email Address</label> -->