<template>
    <v-container style="margin-top:10px;">

            <v-navigation-drawer class="questions-side-nav" app v-model="drawer">
              <v-list class="questions-list">
                <v-list-item v-for="(item, index) in questions" :key="index" class="question-list-item">
                  <v-list-item-content>
                    <div class="flex-space-between">

                      <v-list-item-title
                        class="question-selection"
                        :class="questionAnswered(item.question) ? 'answered-question' : ''"
                        :id="item.question === currentQuestion ? 'selected-question' : null"
                        @click="goToQuestionNumber(item.question)"
                      >
                        {{ item.title }}
                      </v-list-item-title>

                      <span v-if="item.isFlagged"><font-awesome-icon :icon="['fa', 'flag']"  class="side-nav-flag"/></span>
                      <span v-else></span>
                    </div>
                  </v-list-item-content> 
                </v-list-item>
              </v-list>
            </v-navigation-drawer>


            <v-row>

                <v-col cols="1">
                  <v-btn class="view-questions-btn " style="margin-left:-55px; background-color: rgba(0,0,0,0); margin-bottom:-100px; padding-top:25px!important; padding-bottom:25px!important; padding-right:20px!important" @click.stop="drawer = !drawer">
                    <span v-if="!drawer"><font-awesome-icon :icon="['fas', 'bars']" /></span>
                    <span v-else><font-awesome-icon :icon="['fas', 'x']" /></span>
                  </v-btn>
                </v-col>

                <v-col cols="1">
                  
                </v-col>

                <v-col cols="5" class="flex-space-between" style="width:100%">
                    
                    <ToolBar style="flex:3"/>


                </v-col>

                <v-col cols="1"></v-col>

                <v-col cols="2 flex-space-between" style="padding-left:20px; padding-right: 20px;">
                
                    <v-tooltip bottom v-if="this.currentQuestion > 1">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <font-awesome-icon
                            :icon="['fa', 'backward']"
                            @click="previousQuestion()"
                            class="next-prev"
                          />
                        </span>
                      </template>
                      <span>Back [ B ]</span>
                    </v-tooltip>
                  <span v-else></span> <!-- Placeholder for alignment if no icon -->

                  <!-- Tooltip for Next Question -->
                  <v-tooltip bottom v-if="this.currentQuestion < 30">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <font-awesome-icon
                          :icon="['fa', 'forward']"
                          @click="nextQuestion()"
                          class="next-prev"
                        />
                      </span>
                    </template>
                    <span>Next [ N ]</span>
                  </v-tooltip>

                </v-col>


                <v-col cols="2">
                  <v-btn v-if="!this.inReviewMode" class="end-exam-btn" @click="showEndExamConfirmation = true">
                    EXIT EXAM
                  </v-btn>

                  <v-btn v-else color="darken-1" class="end-exam-btn" text @click="goToReviewPage()">GO BACK</v-btn>
                </v-col>
                
            </v-row>

            <!-- DICOM viewer row -->
            <v-row style="max-height: 85vh;">

                <v-col cols="1">
                  <span class="question-number">{{this.currentQuestion}}.</span>
                </v-col>
                <!-- DICOM Images -->
                <v-col cols="1">

                  

                  <div class="dicom-image-previews flex-vertical-justify-start">

                    <div v-for="(jpeg, index) in previewImages" :key="index" class="flex-end image-preview-container">
                      <ImagePreview 
                        :url="jpeg"
                        class="dicom-image-preview"
                      />
                    </div>

 
                  </div>

               
                </v-col>

                <!-- DICOM Viewer -->
                <v-col cols="8">

                  <DicomViewer :squared="false" />

                </v-col>

                <v-col cols="2">
                  
                  <div class="flex-vertical-space-between">

                    <!-- NOT IN REVIEW MODE -->

                    <div v-if="!this.inReviewMode" class="flex-vertical-justify-start">

                      <div v-if="totalQuestionsAnswered == questions.length" class="rad-btn-container submit-answers-container" style="width:100%;">
                          <v-btn  @click="checkAnswers()" :disabled="isButtonDisabled"  class="mt-1 mb-3 rad-btn" style="border-radius: 15px!important; width:193px; margin-bottom:20px!important;">
                            SUBMIT ANSWERS
                          </v-btn>
                      </div>

                    
                      <textarea
                        v-if="this.selectedOption == 'abnormal'"
                        class="rad-txt-field reason-field"
                        rows="3"
                        required
                        v-model="reason"
                        placeholder="State the reason"
                        style="margin-top:20px; min-height:45px; max-height:300px; background-color: rgb(32,32,32)!important; color: rgba(253,253,253,0.85)!important"
                        @input="addUserInputToReasonForAbnormality()"
                        id="answer-textarea"
                      ></textarea>

                      <div class="answer-selection-container">

                        <b-form-radio-group id="radio-group-1" v-model="selectedOption" name="radioOptions">
                          <b-form-radio value="normal" @change="setSelectedOption('normal')"><span style="margin-left:10px">Normal</span></b-form-radio>
                          <b-form-radio value="abnormal" @change="setSelectedOption('abnormal')"><span style="margin-left:10px">Abnormal</span></b-form-radio>
                        </b-form-radio-group>


                        <span class="flag-icon-container" @click="toggleFlag(currentQuestion)">
                          <font-awesome-icon v-if="currentQuestionFlagged" :icon="['fa', 'flag']" />
                          <font-awesome-icon v-else :icon="['far', 'flag']"  />
                        </span>

                      </div>
                    </div>


                    <!-- IN REVIEW MODE -->

                    <div v-else class="flex-vertical-space-between">

                      <div>
                      
                        <div class="review-answer">
                          Your Answer
                        </div>

                        <textarea
                          class="rad-txt-field reason-field"
                          rows="3"
                          required
                          v-model="reason"
                          placeholder="Loading your response"
                          style="margin-top:20px; min-height:45px; max-height:300px; background-color: rgb(32,32,32)!important; color: rgba(253,253,253,0.85)!important"
                          id="answer-textarea"
                          disabled="true"
                        ></textarea>

                        <div v-if="this.overrideEnabled" class="rad-btn-container" style="width:100%; max-width:600px;">
                          <v-btn  @click="override()" class="mt-1 mb-3 rad-btn" style="border-radius:13px!important;">
                            {{ overrideText }}
                          </v-btn>
                        </div>

                      </div>



                      <div>

                        <div class="review-answer">
                          Correct Answer
                        </div>

                        <textarea
                          class="rad-txt-field reason-field correct-answer-field"
                          rows="3"
                          required
                          v-model="currentCorrectAnswer"
                          placeholder="Loading correct answer"
                          style="margin-top:20px; min-height:45px; max-height:300px; background-color: rgb(32,32,32)!important; color: rgba(253,253,253,0.85)!important"
                          id="answer-textarea"
                          disabled="true"
                        ></textarea>

                      </div>

                    </div>








                    <div v-if="!this.inReviewMode" class="counter-container flex-end">
                      
                      <span v-if="!paused"><font-awesome-icon :icon="['fas', 'pause']" style="margin-right:20px" class="play-n-pause" @click="pause()"/></span>
                      <span v-else><font-awesome-icon :icon="['fas', 'play']" style="margin-right:20px" class="play-n-pause" @click="play()"/></span>
                      <CountdownTimer style=""/>
                    </div>
                  </div>

                </v-col>
            </v-row>
   
          <v-dialog v-model="showEndExamConfirmation" persistent max-width="300px">
            <v-card>
              <v-card-title class="text-h5">Confirm Action</v-card-title>
              <v-card-text>Are you sure you want to exit the exam? The timer will be reset when you restart the exam and you will lose all flags!</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="darken-1" text @click="confirmEndExam">EXIT EXAM</v-btn>
                <v-btn color="darken-1" text @click="showEndExamConfirmation = false">CANCEL</v-btn>
              </v-card-actions>


            </v-card>
            
          </v-dialog>
          
    </v-container>
</template>


<script>

import { 
  fetchExamQuestions, 
  gradeAnswers, 
  saveAnswer, 
  getUserAnswers, 
  submitScores, 
  getExamReview, 
  overrideQuestion,
  getActiveSubscriptions,
  getActivePromos 
} from '@/api.js';

import DicomViewer from "../components/DicomViewer.vue";
import ImagePreview from "../components/ImagePreview.vue";
import ToolBar from "../components/ToolBar.vue";
import CountdownTimer from "../components/CountdownTimer.vue";

export default {

  name: 'RapidExam',

  components: {
    DicomViewer,
    ImagePreview,
    ToolBar,
    CountdownTimer
  },

  data() {
    return {
      drawer: false,
      currentQuestion: 1,
      questions: [],
      selectedOption: null,
      reason: "",
      previewImages: [],
      totalQuestionsAnswered: 0,
      paused: false,
      showEndExamConfirmation: false,
      packet: 1,
      answersSavedToDB: {},
      saveInterval: null,
      newAttempt: null,
      inReviewMode: false,
      overrideEnabled: false,
      isNPressed: false,
      isBPressed: false,
      gradedAnswers: [],
      isButtonDisabled: false,
      overrideText: "MARK AS CORRECT",
      currentCorrectAnswer: ""
    };
  },

  watch: {
    currentQuestion(newQuestion) {
      // Check if the current URL's `question` parameter is different from the new question
      if (this.$route.query.question !== newQuestion) {
        this.$router.replace({ 
          query: { ...this.$route.query, question: newQuestion } 
        }).catch(err => {
          // This catch is to handle the error if the navigation is duplicated
          if (err.name !== 'NavigationDuplicated') {
            // Only rethrow the error if it's not a NavigationDuplicated error
            throw err;
          }
          // Optionally, you can handle NavigationDuplicated error specifically if needed
        });
      }


      this.displayAnswerAndUserProvidedReason(newQuestion);

      
    }
  },

  async mounted() {
    let reviewing = this.$route.query.reviewing || false;

    const packet = this.$route.query.packet || '1';
    this.packet = packet;


    let subscriptions = await getActiveSubscriptions(this.$store.state.firebaseUserId);
    const hasPacket = subscriptions.some(sub => sub.packet === parseInt(this.packet));
    if(!hasPacket) {
      let freebies = await getActivePromos(this.$store.state.userEmail);
      const hasPacketInFreebies = freebies.some(sub => sub.packet === parseInt(this.packet));
      if(!hasPacketInFreebies) {
        window.location.href = "/rapids";
        return;
      }
    }


    let questionNumParam  = this.$route.query.question || "1";
    questionNumParam = parseInt(questionNumParam)

    await this.getExamQuestions(packet, questionNumParam==1);
    
    if(questionNumParam > 1) {
      this.currentQuestion = questionNumParam;
      this.$store.dispatch(
        "updateImageId", this.questions[questionNumParam-1].jpegs[0]
      );
      this.goToQuestionNumber(questionNumParam, false);
    }

    

    const newAttemptString = this.$route.query.newAttempt || "false";
    this.newAttempt = newAttemptString === "true";


    if(!this.newAttempt) {
      const answers = await getUserAnswers(this.$store.state.firebaseUserId, this.packet);
      answers.forEach(answer => {
          let providedAnswer = answer.user_answer;
          let questionIndex = answer.question_number -1; 
          this.answersSavedToDB[questionIndex] = providedAnswer;
          this.totalQuestionsAnswered += 1;

          if (providedAnswer === "Normal") {
              this.questions[questionIndex].abnormalSelected = false;
              this.questions[questionIndex].answered = true;
              this.questions[questionIndex].providedReason = "Normal";
          } else {
              this.questions[questionIndex].abnormalSelected = true;
              this.questions[questionIndex].providedReason = providedAnswer;
              this.questions[questionIndex].answered = providedAnswer !== "";
          }
          if (questionIndex === 0) {  
            this.selectedOption = providedAnswer.toLowerCase() === "normal" ? "normal" : "abnormal"; 
          }
          if(this.inReviewMode) {
            if(answer.question_number === questionNumParam) {

              if(this.questions[questionIndex].isAbnormal && answer.user_answer.toLowerCase() !== "normal") {
                if(!this.gradedAnswers[questionIndex].is_correct || this.gradedAnswers[questionIndex].overridden === true) {
                  this.overrideEnabled = true;
                  this.reason = providedAnswer;
                }
              } 
            }
          }
      });




    } else {
      const routeQuery = { ...this.$route.query };
      delete routeQuery.newAttempt; // Remove the newAttempt parameter
      this.$router.replace({ query: routeQuery });
    }


    document.addEventListener('keydown', this.handleKeyDown.bind(this));
    document.addEventListener('keyup', this.handleKeyUp.bind(this));

    this.updatePreviewImages();

    if (reviewing === "true") {

        this.inReviewMode = true;
        // Check if gradedAnswers exists in the store and is appropriately populated
        if (this.$store.state.gradedAnswers && this.$store.state.gradedAnswers.length > 0) {
          this.gradedAnswers = this.$store.state.gradedAnswers;
        } else {
          // Fetch new data if not available in the store
          const examReview = await getExamReview(this.packet, this.$store.state.firebaseUserId);
          this.gradedAnswers = examReview.answers; 
        }

        this.displayAnswerAndUserProvidedReason(questionNumParam);

    }

    this.saveInterval = setInterval(() => {
      if(!this.inReviewMode) {
        this.updateDBWithAnswers();
      }
      this.currentQuestion = parseInt(this.$route.query.question || "1")
    }, 500);

  },

  beforeDestroy() {
    this.updateDBWithAnswers();
    // Clean up the interval when the component is destroyed
    if (this.saveInterval) {
      clearInterval(this.saveInterval);
    }
    document.removeEventListener('keydown', this.handleKeyDown.bind(this));
    document.removeEventListener('keyup', this.handleKeyUp.bind(this));
  },

  methods: {
    displayAnswerAndUserProvidedReason(questionNum) {
      try{
        this.currentCorrectAnswer = JSON.parse(this.questions[questionNum -1].correctAnswers)[0]; 
      } catch (error) {
        console.error(error);
      }
      

      if (this.inReviewMode) {
        if(this.gradedAnswers[this.currentQuestion-1].overridden !== true) {
          this.overrideText = "MARK AS CORRECT";
        } else {
          this.overrideText = "MARK AS INCORRECT";
        }

        const gradedAnswer = this.gradedAnswers[this.currentQuestion-1]

        this.reason = gradedAnswer.user_answer;


        if(this.reason.toLowerCase() !== "normal" && gradedAnswer.pathology_present && (!gradedAnswer.is_correct || gradedAnswer.overridden)) {
          this.overrideEnabled = true;
        } else {
          this.overrideEnabled = false;
        }
      }
    },
    override() {
      if(this.gradedAnswers[this.currentQuestion-1].overridden !== true) {
        this.gradedAnswers[this.currentQuestion-1].overridden = true;
        overrideQuestion(
          this.$store.state.firebaseUserId,
          this.packet,
          this.currentQuestion,
          this.reason,
          true
        );
        this.$notify({
          group: 'dark',
          title: 'Marked Correct',
          text: 'You will be able to see the updated score by clicking the GO BACK button',
          duration: 5000
        });
        this.overrideText = "MARK AS INCORRECT";
      } else {
        this.gradedAnswers[this.currentQuestion-1].overridden = false;
        overrideQuestion(
          this.$store.state.firebaseUserId,
          this.packet,
          this.currentQuestion,
          this.reason,
          false
        );
        this.$notify({
          group: 'dark',
          title: 'Marked Incorrect',
          text: 'You will be able to see the updated score by clicking the GO BACK button',
          duration: 5000
        });
        this.overrideText = "MARK AS CORRECT";
      }

    },
    updateDBWithAnswers() {
      this.questions.forEach(question => {
        const savedAnswer = this.answersSavedToDB[question.question];
        const currentAnswer = question.abnormalSelected ? question.providedReason : "Normal";

        if (question.answered && !savedAnswer) {
          console.log("Answer for question", question.question, "not saved yet.");
          saveAnswer(
              this.$store.state.firebaseUserId,
              this.packet,
              question.question,
              currentAnswer
          )
          this.answersSavedToDB[question.question] = currentAnswer;
        } else if (question.answered && !question.abnormalSelected && savedAnswer !== currentAnswer) {
          console.log("Updating answer for question", question.question, "to normal.");
          saveAnswer(
              this.$store.state.firebaseUserId,
              this.packet,
              question.question,
              currentAnswer
          )
          this.answersSavedToDB[question.question] = currentAnswer;
        } else if (question.answered && question.abnormalSelected && savedAnswer !== currentAnswer) {
          console.log("Updating answer for question", question.question, "to abnormal with reason.");
          saveAnswer(
              this.$store.state.firebaseUserId,
              this.packet,
              question.question,
              currentAnswer
          )
          this.answersSavedToDB[question.question] = currentAnswer;
        }
      });
    },
    confirmEndExam() {
      this.showEndExamConfirmation = false; // Close the dialog
      this.endExam(); // Proceed to end the exam
    },
    pause() {
      this.paused = true;
      this.$store.dispatch('updatePaused', true); 
    },
    play() {
      this.paused = false;
      this.$store.dispatch('updatePaused', false); 
    },
    handleKeyDown(event) {

      const activeElement = document.activeElement;

      if(activeElement.id === 'answer-textarea') {
          return;
      }

      if (event.key.toLowerCase() === "n") {
          if(!this.isNPressed) {
            this.isNPressed = true;
            this.nextQuestion();
            this.play();
            return;
          }
          
      }

      if (event.key.toLowerCase() === 'b') { 
          if(!this.isBPressed) {
            this.isBPressed = true;
            this.previousQuestion();
            this.play();
            return;
          }
          
      }

    },
    handleKeyUp() {
      if(this.isNPressed){
        this.isNPressed = false;
      }
      if(this.isBPressed) {
        this.isBPressed = false;
      }
    },
    endExam() {
      this.$router.push("/my-rapids?packet="+this.packet);
    },
    goToReviewPage() {
      //this.$router.push("/review-results-page?packet="+this.packet);
      window.location.href = "/review-results-page?packet="+this.packet;
    },
    updateExamResults(results) {
      // Logic to update Vuex store or local state
      console.log('Updated Exam Results:', results);
      this.$store.dispatch('updateExamResults', results); 


      let payload = {
        "firebase_user_id": this.$store.state.firebaseUserId,
        "exam_package": this.packet,
      };

      let graded_answers = [];

      // Ensure 'results' is an object and has properties before iterating
      if (results && typeof results === 'object' && !Array.isArray(results)) {
        Object.keys(results).forEach(key => {
          graded_answers.push({
            "question": parseInt(key),  // Assuming keys are the question numbers
            "is_correct": results[key]["correct"]
          });
        });
      }

      payload["graded_answers"] = graded_answers;

      submitScores(payload);

      // Redirect to the review results page
      this.goToReviewPage()
    },
    async checkAnswers() {

      this.isButtonDisabled = true;

      this.$notify({
        group: 'dark',
        title: 'Generating Results',
        text: 'Please wait while our AI reviews your answers!',
        duration: 80000
      });

      this.updateDBWithAnswers();

      let payload = {"answers": []};
      let results = {};

      this.questions.forEach(question => {
          results[question.question] = {
              "correctAnswers": question.correctAnswers,
              "abnormalSelected": question.abnormalSelected ? "Yes" : "No",
              "providedReason": question.abnormalSelected ? question.providedReason : "",
              "pathologyPresent": question.isAbnormal ? "Yes" : "No",
              "correct": !question.isAbnormal && !question.abnormalSelected || undefined,
          };

          if (!question.isAbnormal && !question.abnormalSelected) {
            results[question.question]["correct"] = true;
          }

          if (question.isAbnormal && !question.abnormalSelected) {
            results[question.question]["correct"] = false;
          }

          if (!question.isAbnormal && question.abnormalSelected) {
            results[question.question]["correct"] = false;
          }

          if (question.isAbnormal && question.abnormalSelected) {
              let correctAnswers = [];
              try {
                  correctAnswers = JSON.parse(question.correctAnswers);
              } catch (e) {
                  console.error('Failed to parse correctAnswers:', question.correctAnswers);
                  correctAnswers = [];
              }

              payload.answers.push({
                  "firebase_user_id": this.$store.state.firebaseUserId,
                  "exam_package": this.packet,
                  "question": question.question,
                  "user_answer": question.providedReason,
                  "correct_answers": correctAnswers,
              });
          }
      });

      if (payload.answers.length > 0) {
          try {
              setTimeout(() => {
                this.isButtonDisabled = false; // Re-enable the button after 8 seconds
              }, 8000); // 8000 milliseconds equals 8 seconds
              results = await gradeAnswers(payload, results);
          } catch (error) {
              console.error("Failed during grading answers:", error);
              this.errorMessage = error.message;
              return; // Optional: handle error state in the UI
          }
      }

      this.updateExamResults(results);
    },



    preloadImages(imageUrls) {
      imageUrls.forEach(url => {
        const img = new Image();
        img.src = url;
   
        img.onerror = () => console.log(`Failed to load: ${url}`);
      });
    },



    async getExamQuestions(examNumber, autoDispatch=true) {
      const { questions, allJpegs } = await fetchExamQuestions(examNumber);
      this.questions = questions;

      this.allJpegs = allJpegs;

      if (questions.length > 0 && autoDispatch) {
        this.$store.dispatch('updateImageId', questions[0].jpegs[0]);
        this.previewImages = questions[0].jpegs.slice(1);
      }
      this.preloadImages(allJpegs);
    },


    getQuestion(questionNumber) {
      return this.questions.find(q => q.question === questionNumber);
    },





    setSelectedOption(option) {
      this.selectedOption = option;
      const question = this.getQuestion(this.currentQuestion);

      if (question) {
        if (option === 'normal') {
          question.abnormalSelected = false;

          if(!question.answered) {
            question.answered = true;
            this.totalQuestionsAnswered += 1;
            console.log("questions answered ", this.totalQuestionsAnswered);
          }

        } else if (option === 'abnormal') {
          if(question.providedReason == "") {
            if(question.answered && this.totalQuestionsAnswered > 0) {
              this.totalQuestionsAnswered -= 1;
              console.log("answered questions ", this.totalQuestionsAnswered)
            }
            question.answered = false;
          }

          question.abnormalSelected = true;
        }
      }
    },



    addUserInputToReasonForAbnormality() {
      const question = this.getQuestion(this.currentQuestion);
      question.providedReason = this.reason;

      //If there question was not already answered
      if(question.providedReason !== "" && !question.answered) {
        this.totalQuestionsAnswered += 1;
        question.answered = true;
        console.log("questions answered ", this.totalQuestionsAnswered)
        return;
      }

      //If the question was previously answered but the answer is removed
      if(question.providedReason == "") {
        if(question.answered && this.totalQuestionsAnswered > 0) {
          this.totalQuestionsAnswered -= 1;
        }
        console.log("questions answered ", this.totalQuestionsAnswered)
        question.answered = false;

        return;
      }   

    },


    toggleFlag(questionNumber) {
      const question = this.getQuestion(questionNumber);
      if (question) {
        question.isFlagged = !question.isFlagged;
      }
    },

    currentQuestionAnswered() {
      return this.questions[this.currentQuestion-1]["answered"];
    },

    currentProvidedReason() {
      return this.questions[this.currentQuestion-1]["providedReason"];
    },

    prefillValuesIfAnswerProvided() {
      console.log("curent question: ", this.currentQuestion)
      const question = this.getQuestion(this.currentQuestion);

      console.log("question ", question);
      if(question.answered) {
        if(question.abnormalSelected) {
          this.selectedOption = "abnormal";
          this.reason = question.providedReason;
          if(question.isAbnormal) {
            this.overrideEnabled = true;
          }
        } else {
          this.selectedOption = "normal";
          if(this.inReviewMode) {
            this.reason = "Normal";
            this.overrideEnabled = false;
          } else {
            this.reason = "";
          }
        }
      } else {
          this.selectedOption = null;
          this.reason = "";
      }
    },

    goToQuestionNumber(questionNumber, dispatch=true) {
      this.currentQuestion = questionNumber;
      this.prefillValuesIfAnswerProvided();
      if(dispatch) {
        this.$store.dispatch(
          "updateImageId", this.questions[this.currentQuestion-1].jpegs[0]
        );
      }
      this.updatePreviewImages();
      this.play();
    },

    updatePreviewImages() {
      this.previewImages = [];
      if(this.questions[this.currentQuestion-1].jpegs.length == 1) {
        return;
      }
      for(let i = 0; i < this.questions[this.currentQuestion-1].jpegs.length; i++) {
        this.previewImages.push(this.questions[this.currentQuestion-1].jpegs[i]);
      }
    },

    nextQuestion() {
      if(this.currentQuestion == this.questions.length) {
        return;
      }
      this.currentQuestion += 1;
      this.prefillValuesIfAnswerProvided();
      this.$store.dispatch(
        "updateImageId", this.questions[this.currentQuestion-1].jpegs[0]
      );
      this.updatePreviewImages();
      this.play();
    },

    previousQuestion() {
      if(this.currentQuestion == 1) {
        return;
      }
      this.currentQuestion -= 1;
      this.prefillValuesIfAnswerProvided();
      this.$store.dispatch(
        "updateImageId", this.questions[this.currentQuestion-1].jpegs[0]
      );
      this.updatePreviewImages();
      this.play();
    },
    questionAnswered(questionNumber) {
      const question = this.getQuestion(questionNumber);
      if(question.answered) {
        return true;
      }
      return false;
    }
    
  },
  computed: {
    currentQuestionFlagged() {
      const current = this.questions.find(q => q.question === this.currentQuestion);
      return current ? current.isFlagged : false;
    },
  }
}
</script>





<style>

    .review-answer {
      font-size: 20px;
      margin-bottom: 14px;
    }

    .v-list-item {
      padding: 0px 10px 0px 18px!important;
    }

    .answered-question {
      color: rgba(245,245,245,0.4)!important;
    }

    .play-n-pause:hover {
      cursor: pointer;
    }

    .end-exam-btn {
      background-color: rgba(0,0,0,0)!important;
      font-size: 23px!important;
      padding: 25px 20px 25px 20px!important;
      margin-top:-10px!important;
    }

    .counter-container {
  
      margin-top:20px;
      
    }

    .custom-radio-colors .custom-control-input:checked ~ .custom-control-label::before {
      background-color: #ff5733!important; /* Custom color */
      border-color: #ff5733!important; /* Custom border color to match the background */
    }

    #selected-question {
      font-size: 21px;
      color: rgba(255,255,255,0.95)!important;
    }

    .question-selection:hover {
      cursor: pointer;
      font-size: 21px;
      color: rgba(255,255,255,0.95);
    }

    .question-selection {
      color: rgba(255,255,255,0.75);
    }

    .questions-list {
      padding: 5px 10px 5px 10px!important;
    }

    .side-nav-flag {
      font-size: 16px;
      margin-bottom:4px!important;
      color: rgba(255,255,255,0.75);
    }

    .questions-side-nav {
      background-color: rgb(12,12,12)!important;
      border-right: 1px solid rgba(0,0,0,1);
      width:190px!important;
    }

    .view-questions-btn {
      margin-top:-120px!important;
      font-size: 30px!important;
    }

    .dicom-image-previews {
      display: flex; /* Ensures flex properties apply */
      flex-direction: column; /* Aligns children vertically */
      align-items: flex-end; /* Aligns children to the start of the flex container */
    }

    .image-preview-container {
      width:160px;
      height:160px;
      overflow-y: hidden;
      background-color:black;
      margin-left:25px;
      margin-top: 20px;
      border-radius: 3px;
    }

    .image-preview-container:first-of-type {
      margin-top: 0px;
    }


    .image-preview-container:hover {
      cursor: pointer;
    }



    .dicom-image-preview {
      
      width: auto; /* Adjust width as necessary */
   
      height: 95%; /* Maintains aspect ratio */
      border-radius: 3px;
      margin: 0 auto;
      margin-top:4px!important;
    }

    .dicom-image-preview-container:first-of-type {
      margin-top: 0px;
    }

    .flex-end {
      display: flex;
      justify-content: end;
    }

    .next-prev {
      font-size: 40px!important;
      color: rgba(255,255,255,0.75);
    }

    .next-prev:hover {
      cursor: pointer;
      color: rgba(255,255,255,0.95);
    }

    .next-and-prev-row {
      padding:5px 15px 5px 15px;
    }


    .countdown-container {
      display: flex; /* Ensure it’s a flex container */
      justify-content: flex-end; /* Align children to the right */
      margin-top:25px!important;
    }

    .submit-answers-container {
      width: 100%;
    }


    .correct-answer-field {
      margin-bottom: 0px!important;
    }


    .reason-field {
      margin-top: 40px;
      font-size:15px;
      background-color: rgb(32,32,32)!important;
      color: white!important;
      width:200px;
    }


    .answer-selection-container {
      font-size:18px;
      padding: 20px 25px 20px 25px;
      border-radius: 13px;
      background-color: rgb(32,32,32);
      display: inline-block;
      position: relative; /* Needed to position the flag icon absolutely relative to this container */
      width:200px;
    }

    .flag-icon-container {
      position: absolute; /* Position the flag icon absolutely within the answer selection container */
      top: 15px; /* Adjust this value to place the icon correctly in the top-right corner */
      right: 20px; /* Adjust this value to place the icon correctly in the top-right corner */
      font-size:19px;
      color: rgba(253,253,253,0.8);
      margin-left:10px;
    }

    .flag-icon-container:hover {
      cursor: pointer;
    }

    .question-number {
        text-align: right;
        flex:2; 
        margin-left: -35px;
    }

    @media (max-width: 1903px) {
      .reason-field {
        margin-top: 25px;
      }

      .view-questions-btn {
        margin-top:-110px!important;
        
      }
    }





</style>
