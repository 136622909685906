<template>
  <v-container class="extra-padded-container">
    <div class="about-us-outer-container">
      <div class="about-us-inner-container">
        <h1 class="about-us-h1">About Us</h1>

        <p class="about-us-content lg">
          At <b class="mr ml">RadBytes</b> we are commited to transform the way we learn <b style="ml">Radiology</b>.<br>
          By combining <b>technology</b>, <b>art</b> and <b>design</b>, we strive to create an immersive<br>
          <span  style="display:inline-block;"></span>learning experience that captivates you, fueling your passion for <b>Radiology</b>.
        </p>

        <p class="about-us-content sm">
          At <b class="mr ml">RadBytes</b> we are commited to transform the way we learn <b style="ml">Radiology</b>.
          By combining <b>technology</b>, <b>art</b> and <b>design</b>, we strive to create an immersive
          <span class="mr" style="display:inline-block;"></span>learning experience that captivates you, fueling your passion for <b>Radiology</b>.
        </p>

        <p class="about-us-quote"><b>Let your journey begin.</b></p>



        <div class="founders">

          <div class="founder-img-container">
            Paymon
            <div class="founder-img">
              <img class="team-member" src="/imgs/Paymon.webp">
            </div>
          </div>

          <div class="founder-img-container">
            Yaz
            <div class="founder-img">
              <img class="team-member" src="/imgs/Yaz.webp">
            </div>
          </div>


          <div class="founder-img-container">
            Zenos
            <div class="founder-img">
              <img class="team-member" src="/imgs/Zenos.webp">
            </div>
          </div>
        </div>
      </div>





    </div>
  </v-container>
</template>

<script>
export default {
  name: 'AboutUs',
}
</script>

<style scoped>

.team-member {
  width: 280px;
  height: auto;
}

.sm {
  display:none;
}

.founder-img-container {
  text-align: center!important;
}

.mr {
  margin-right: 11px;
}

.ml {
  margin-left: 14px;
}

.about-us-h1 {
  margin-bottom: 50px;
  text-align: left;
  margin-left:10px;
}

.about-us-content {
  margin-bottom: 50px!important;
}

.about-us-quote {
  margin-bottom: 65px!important;
  text-align: left;
  margin-left:13px;
}

.about-us-outer-container {
  display: flex;
  justify-content: center;
}

.about-us-inner-container {
  text-align: center;
}

.founders {
  display: flex;
  justify-content: space-between;
}


@media (max-width: 1263px) {
  .mr {
    margin-right: 0px;
  }

  .ml {
    margin-left: 0px;
  }

  .about-us-inner-container {
    text-align: left;
  }

  .about-us-h1 {
    text-align: left;
    margin-left:0px;
  }

  .about-us-quote {
    margin-left: 0px;
  }

  .sm {
    display:block;
  }

  .lg {
    display:none;
  }
}


@media (max-width: 959px) {
  .founders {
    flex-direction: column;
  }

  .team-member {
    width:400px;
    height: auto;
    max-width:90vw;
    margin-bottom:30px;
  }

  .about-us-quote {
    text-align: left;
  }

}

</style>
