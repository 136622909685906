<template>

        <div>
            <img 
                class="dicom-image-preview" 
                :src=this.url
                @click="swapImage()"
            >
        </div>
 

</template>


<script>

    export default {

        props: ["url"],

        methods: {

            swapImage() {
                
                const clickedImageSrc = event.target.src;

                // Get the source of the main image using refs
                // const prevImg = this.$store.state.imageId;

                this.$store.dispatch(
                    // "updateImageId", clickedImageSrc.replace(".jpeg", ".dcm")
                    "updateImageId", clickedImageSrc.replace(".jpeg", ".jpeg")
                );

                // event.target.src = prevImg.replace(".dcm", ".jpeg");
     
            },

        }
    }

</script>


<style>



</style>