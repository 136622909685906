<template>
  <v-form>
    <v-container class="mt-5 contact-container extra-padded-container">
      <v-row>
        <v-col cols="0" xs="0" sm="0" md="0" lg="1" xl="1"></v-col>

        <v-col class="form-column" cols="12" xs="12" sm="12" md="6" lg="5" xl="5">
          <input
            type="text"
            required
            v-model="userName" 
            class="rad-txt-field"
            placeholder="Name"
            style="-webkit-text-fill-color:black!important"
          />
          <input
            type="email"
            required
            v-model="userEmail" 
            class="rad-txt-field"
            placeholder="Email"
            style="-webkit-text-fill-color:black!important"
          />
          <textarea
            class="rad-txt-field"
            rows="5"
            required
            v-model="userMessage" 
            placeholder="Comment"
            style="-webkit-text-fill-color:black!important"
          ></textarea>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="6" lg="5" xl="5" class="contact-info">
          <div class="contact-us-quote">"We would love to hear your thoughts"</div>
          <div class="contact-us-header-container">
            <h1 class="contact-us-header">Contact <br class="conditional-br">Us</h1>
          </div>
        </v-col>

        <v-col cols="0" xs="0" sm="0" md="0" lg="1" xl="1"></v-col>
      </v-row>

      <v-row>
        <v-col cols="0" xs="0" sm="0" md="0" lg="1" xl="1"></v-col>

        <v-col cols="12" xs="12" sm="12" md="6" lg="5" xl="5" class="rad-btn-row">
            <div class="rad-btn-container" style="width:100%; max-width:600px">
                <v-btn @click="submitUserMessage()" class="mt-1 mb-3 rad-btn">
                Send
                </v-btn>
            </div>
        </v-col>

        <v-col cols="0" xs="0" sm="0" md="0" lg="1" xl="1"></v-col>
      </v-row>

      <v-row>
        <v-col cols="0" xs="0" sm="0" md="0" lg="1" xl="1"></v-col>
        <v-col cols="12" xs="12" sm="12" md="6" lg="5" xl="5">
          <div class="contact-socials mt-5">
            
            <a href="https://www.instagram.com/radbytesed/" target="_blank">
              <font-awesome-icon class="social-icon" :icon="['fab', 'instagram']" />
            </a>

            <a href="https://www.youtube.com/channel/UCY_2TUrRRqsKFzrwMV1J3UA" target="_blank">
              <font-awesome-icon class="social-icon" :icon="['fab', 'youtube']" />
            </a>



            <a href="https://twitter.com/RadBytesEd" target="_blank">
              <font-awesome-icon class="social-icon" :icon="['fab', 'twitter']" />
            </a>


            <a href="https://www.facebook.com/profile.php?id=61555453722385" target="_blank">
              <font-awesome-icon class="social-icon" :icon="['fab', 'facebook']" />
            </a>



          </div>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="6" lg="5" xl="5" class="contact-info">


          <div class="radbytes-email mt-5">
            email: <a href="mailto:contact@radbytes.org">contact@radbytes.org</a>
          </div>


        </v-col>

        <v-col cols="0" xs="0" sm="0" md="0" lg="1" xl="1"></v-col>
      </v-row>
    </v-container>
  </v-form>
</template>



<script>

  import { sendMessage } from '@/api.js';

  export default {
    name: 'ContactPage',
    layout: 'radbytes',
    data() {
      return {
        userName: "",
        userEmail: "",
        userMessage: ""
      }
    },
    methods: {
      submitUserMessage() {
        const payload = {
          name: this.userName,
          email: this.userEmail,
          message: this.userMessage
        }

        sendMessage(payload);

        this.userName = "";
        this.userEmail = "";
        this.userMessage = "";

        this.$notify({
            group: 'dark',
            title: 'Message Sent',
            text: 'We will get back to you as soon as possible!',
            duration: 10000
        });
        
      }
    }
  }
</script>

<style>

    .social-icon {
        margin-right: 15px;
    }

    .social-icon:hover {
        margin-right: 5px;
        font-size: 50px!important;
        transition: all 0.25s;
        cursor:pointer;
    }

    .contact-container {
        font-size:23px;
    }



    .contact-us-quote {
        font-size: 1.4rem;
        margin-bottom: 25px;
    }

    .my-container {
        display: flex;
        flex-direction: row;
        padding: 70px;
    }

    .form-container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    .contact-info {
        display: flex;
        flex-direction: column;
        align-items: flex-end; /* Aligns the content to the right */
        text-align: right; 
    }

    .contact-us-header {
        font-size: 6.5rem;
    }

    .radbytes-email {
        font-size:1.25rem;
        font-weight: bold;
    }

    .contact-socials {
        font-size: 2.5rem;
    }

@media (max-width: 960px) { /* Adjust the breakpoint as needed */

    .contact-socials, .radbytes-email{
        width: 100%; /* Ensure full width for centering */
        display: flex; /* Use flex layout for the icons container */
        justify-content: center; /* Center flex items horizontally */
        flex-wrap: wrap; /* Allow items to wrap in small screens */
    }

    .contact-socials {
        margin-bottom:-30px!important;
    }




    .rad-btn-row {
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .form-column {
        order: 2; /* Move the form column to the end */
        display: flex; /* Make it a flex container */
        flex-direction: column; /* Stack the children vertically */
        align-items: center; /* Center the items horizontally in the column */
        width: 100%; /* Ensure full width to align text fields properly */
    }
    .contact-info {
        order: 1; /* Move the contact info column to the beginning */
        display: flex;
        flex-direction: column;
        align-items: center; /* Align items in the center */
        text-align: start;
    }

    .form-column, .contact-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .contact-us-quote{
        order: 4;
    }

    .contact-us-header {
        order: 3;
        font-size:95px;
    }

    .conditional-br {
        display: none !important;
    }


}


@media (max-width: 560px) {
    .contact-us-header {
        font-size:75px;
    }
}

@media (max-width: 437px) {
    .contact-us-header {
        font-size:60px;
    }
}

@media (max-width: 363px) {
    .contact-us-header {
        font-size:50px;
    }
}

</style>