// Import Firebase modules
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// Firebase configuration object
const firebaseConfig = {
  apiKey: "AIzaSyABTWcuC6JBA6VtjEG3uCnGFc4-EUmjFfc",
  authDomain: "radbytes-47baa.firebaseapp.com",
  projectId: "radbytes-47baa",
  storageBucket: "radbytes-47baa.appspot.com",
  messagingSenderId: "761453219923",
  appId: "1:761453219923:web:c214764fdc48f8862136a6",
  measurementId: "G-4ZT4TRGWJ2"
};

// Initialize Firebase App
const app = initializeApp(firebaseConfig);

// Get Firebase Auth instance
const auth = getAuth(app);

// Export the Auth service for use throughout your app
export { auth };
