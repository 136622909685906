import Vue from 'vue'
import App from './App.vue'
import Vuetify from 'vuetify';
import '@mdi/font/css/materialdesignicons.min.css'; // Correct path for MDI CSS

import store from './store';

import Notifications from 'vue-notification';
Vue.use(Notifications);



import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

import 'vuetify/dist/vuetify.min.css';
import colors from 'vuetify/es5/util/colors';

import './assets/global.css';

// Importing Cornerstone libraries
import cornerstone from 'cornerstone-core';
import cornerstoneMath from 'cornerstone-math';
import cornerstoneTools from 'cornerstone-tools';
import cornerstoneWebImageLoader from 'cornerstone-web-image-loader';
import cornerstoneWADOImageLoader  from 'cornerstone-wado-image-loader';
import cornerstoneDICOMImageLoader from '@cornerstonejs/dicom-image-loader';

import dicomParser from 'dicom-parser';
import hammerjs from 'hammerjs';

import router from './router/index.js'; 

// Importing Font Awesome libraries
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Add all Font Awesome icons to the library
library.add(fas, far, fab);

// Register the FontAwesomeIcon component globally
Vue.component('font-awesome-icon', FontAwesomeIcon);




Vue.use(Vuetify);

Vue.config.productionTip = false;


// Configuring cornerstone-tools
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneTools.external.Hammer = hammerjs;

// Initialize cornerstone libraries and make them globally available
Vue.prototype.$cornerstone = cornerstone;
Vue.prototype.$cornerstoneMath = cornerstoneMath;
Vue.prototype.$cornerstoneTools = cornerstoneTools;
Vue.prototype.$cornerstoneWebImageLoader = cornerstoneWebImageLoader;
Vue.prototype.$cornerstoneWADOImageLoader = cornerstoneWADOImageLoader;
Vue.prototype.$cornerstoneDICOMImageLoader = cornerstoneDICOMImageLoader;
Vue.prototype.$dicomParser = dicomParser;

const vuetify = new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        background: 'rgb(12,12,12)',
        primary: colors.blue.darken2,
        accent: colors.grey.darken3,
        secondary: colors.amber.darken3,
        info: colors.teal.lighten1,
        warning: colors.amber.base,
        error: colors.deepOrange.accent4,
        success: colors.green.accent3,
      },
    },
  },
});

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App),
  created() {
  }
}).$mount('#app');