<template>
    <v-container class="mt-5 extra-padded-container">
      <v-row>
        <v-col cols="12">
          <h1>Please Verify Your Email</h1>
          <p>If it's not in your inbox, try checking the spam folder!</p>
          <p class="sm-txt">
            Can't find the verification email?
          </p>
          <div class="rad-btn-container" style="width:100%; max-width:600px; border-radius: 5px;">
            <v-btn
              @click="resendVerificationEmail"
              :disabled="cooldownActive"
              class="mt-1 mb-3 rad-btn"
              style="border-radius: 5px!important;"
            >
              request a new one
            </v-btn>
            <p v-if="cooldownActive" class="cooldown-message" style="margin-top:0px;">
              You can request a new verification email in {{ formattedCountdown }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import { onAuthStateChanged, sendEmailVerification } from 'firebase/auth';
  import { auth } from '../firebaseConfig';
  
  export default {
    data() {
      return {
        emailVerified: false,
        checkInterval: null,
        cooldownActive: false,
        countdown: 300,
        timer: null
      };
    },
    computed: {
      formattedCountdown() {
        const minutes = Math.floor(this.countdown / 60);
        const seconds = this.countdown % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      }
    },
    methods: {
      checkEmailVerification() {
        onAuthStateChanged(auth, (user) => {
          if (user) {
            user.reload().then(() => {
              if (user.emailVerified) {
                clearInterval(this.checkInterval);
                this.emailVerified = true;
                this.$router.push('/rapids'); // Redirecting to the home page
              } else {
                console.log("user not verified");
              }
            });
          }
        });
      },
      resendVerificationEmail() {
        const user = auth.currentUser;
        if (user && !this.cooldownActive) {
          sendEmailVerification(user).then(() => {
            console.log('Verification email sent.');
            // this.startCooldown();
          }).catch((error) => {
            console.error('Error sending verification email:', error);
          });
        } else {
          console.log("No user logged in or cooldown is active");
        }
        this.startCooldown();
      },
      startCooldown() {
        this.cooldownActive = true;
        this.countdown = 300; // 300 seconds = 5 minutes
        this.timer = setInterval(() => {
          this.countdown -= 1;
          if (this.countdown <= 0) {
            clearInterval(this.timer);
            this.cooldownActive = false;
          }
        }, 1000);
      }
    },
    mounted() {
      this.checkEmailVerification();
      this.checkInterval = setInterval(this.checkEmailVerification, 5000); // Check every 5 seconds
    },
    beforeDestroy() {
      if (this.checkInterval) {
        clearInterval(this.checkInterval);
      }
      if (this.timer) {
        clearInterval(this.timer);
      }
    }
  }
  </script>
  
  <style scoped>
  .cooldown-message {
    margin-top: 20px;
    font-size: 16px;
    color: rgba(250,250,250,0.75);
  }
  </style>
  