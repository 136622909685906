<template>

  <div v-if="isLoading">
    <div class="loader-container">
      <div class="spinner"></div>
      <div class="loading-text">LOADING...</div>
    </div>
  </div>
  

  <v-container v-else class="extra-padded-container">

    <div class="flex-space-between" style="margin-bottom:20px;">
      <h3>{{ pageHeader }}</h3>
      <h3>Score: {{correctAnswers}} / {{correctAnswers + incorrectAnswers}}</h3>
    </div>


    <v-data-table
      :headers="headers"
      :items="review.answers"
      class="elevation-1 custom-table"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td style="text-align: center; border: none; padding-top:7px; padding-bottom:7px;">
            {{ item.question_number }}
          </td>


          <td v-if="item.is_correct" style="text-align: center; border: none; padding-top:7px; padding-bottom:7px;">
            Correct
          </td>
          <td v-else style="text-align: center; border: none; padding-top:7px; padding-bottom:7px;">
            <b>Incorrect</b>
          </td>


          <td style="text-align: center; border: none; padding-top:7px; padding-bottom:7px;">
            {{ item.user_answer }}
          </td>
          <td style="text-align: center; border: none; padding-top:7px; padding-bottom:7px;">
            {{ item.correct_answer }}
          </td>
          <td style="text-align: center; border: none; padding-top:7px; padding-bottom:7px;">
            <v-btn @click="reviewQuestion(item.question_number)">
              REVIEW
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div class="flex-space-between" style="margin-top:25px">
      <div></div>
      
      
      <a href="/my-rapids"><v-btn style="padding:25px!important; border-radius:12px;">BACK TO MY RAPIDS</v-btn></a>
    </div>
  </v-container>
</template>

<script>
import { getExamReview } from '@/api.js';
// import { question } from 'fontawesome';
import $ from 'jquery';
export default {
    name: 'ReviewResultsPage',
    data() {
      return {
        isLoading: true,
        packet: null,
        review: [],
        pageHeader: "",
        score: "",
        correctAnswers: 0,
        incorrectAnswers: 0,
        headers: [
          { text: 'Question Number', align: 'center', value: 'question_number', sortable: false },
          { text: 'Result', align: 'center', value: 'is_correct', sortable: false },
          { text: 'Your Answer', align: 'center', value: 'user_answer', sortable: false },
          { text: 'Correct Answer', align: 'center', value: 'correct_answer', sortable: false },
          { text: '', align: 'center', value: "", sortable: false}
        ],
      }
    },
    async mounted() {
      this.packet = this.$route.query.packet || '1';
      this.pageHeader = "Results For Exam " + this.packet;
      this.review = await getExamReview(this.packet, this.$store.state.firebaseUserId)
      this.score = this.review.score;
      this.correctAnswers = this.review.total_correct_answers;
      this.incorrectAnswers = this.review.total_incorrect_answers;
      this.$store.dispatch(
          "updateGradedAnswers", this.review["answers"]
      );
      this.isLoading = false;
      $(".vue-notification").attr('style', 'display: none !important');
      this.removeTableBorders();
      this.resizeHeaders();
    },
  
    computed: {

    },
    methods: {
      reviewQuestion(questionNumber) {
        const href="/rapid-exam?packet=" + this.packet + "&question=" + questionNumber + "&reviewing=true";
        window.location.href = href;
      },
      getAnswerOfUser(result) {
        if(result.abnormalSelected == "No") {
          return "Normal";
        } 
        return result.providedReason;
      },
      removeTableBorders() {
        this.$nextTick(() => { // Ensure the DOM has updated
            $('.elevation-1 .v-data-table__wrapper table thead th').css('border', 'none');
            $('.elevation-1 th').css('padding-bottom', '40px');
          
            $('.elevation-1 .v-data-footer').css('border', 'none');
            $('.elevation-1 .v-data-footer').css('margin-top', '20px');
        });
      },
      resizeHeaders() {
        this.$nextTick(() => {
          $("hr").css("font-size", "20px!important");
        })
      }
    }
}
</script>


<style scoped>


  .extra-padded-container {
    padding: 20px;
  }
  .elevation-1 {
    border-radius: 30px;
    padding: 50px;

    margin: 0 auto;
  }
  .elevation-1 .v-data-table .v-data-table__wrapper table {
    border-collapse: separate;
    border-spacing: 0;
  }
  .elevation-1 .v-data-table .v-data-table__wrapper table td,
  .elevation-1 .v-data-table .v-data-table__wrapper table th {
    border: none!important;  /* Removes the borders */
  }
 
  .rad-btn:hover {
    background-color:rgba(253,253,253,0.93)!important;
    border: 2px solid rgba(253,253,253,0.94)!important;
    transition: all ease-in-out 0.3s;
    color:black!important;
  }

  .custom-table {
    font-size: 20px!important;
  }

  .start-exam-btn {
    width:110px;
  }


</style>