<template>
    <v-container class="mt-5">
      <!-- Row for the content -->
      <v-row>
        <v-col cols="12" sm="8" md="6" lg="4" class="mx-auto">
  
          <!-- Heading -->
          <h1 class="mb-4">Forgot Your Password?</h1>
  
          <!-- Subtext -->
          <div class="mb-5" style="font-size:18px;">Enter the email address you registered with</div>
  
          <!-- Email Input Form -->
          <v-form @submit.prevent="sendResetEmail">
            <v-text-field
              label="Email"
              v-model="email"
              type="email"
              required
              outlined
              class="mb-2"
            ></v-text-field>
  
            <!-- Submit Button -->
            <v-btn type="submit" color="" block large>Send Reset Link</v-btn>
          </v-form>
  
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  
  
  <script>
  import { sendPasswordResetEmail } from 'firebase/auth';
  import { auth } from '../firebaseConfig';
  
  export default {
    data() {
      return {
        email: ''
      };
    },
    methods: {
      sendResetEmail() {

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Check if the email is valid
        if (!emailPattern.test(this.email)) {
            this.$notify({
                group: 'dark',
                title: 'Error',
                text: 'Please input a valid email address',
                duration: 2500 // Set to 8000ms, adjust duration as necessary
            });
            return;
        }

        sendPasswordResetEmail(auth, this.email)
          .then(() => {
            this.$notify({
                group: 'dark',
                title: 'Reset link sent',
                text: 'If the provided email address is registered on our system, then you will recieve an email with a reset link.',
                duration: 10000 // Set to 8000ms, adjust duration as necessary
            });
            this.email = ''; // Clear the input after sending.
          })
          .catch(error => {
            console.log(error);
            this.$notify({
                group: 'dark',
                title: 'Error',
                text: 'Error sending reset email',
                duration: 2500 // Set to 8000ms, adjust duration as necessary
            });
          });
      }
    }
  }
  </script>
  

