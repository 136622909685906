<template>
  <div class="image-container">
    <img src="https://rapids.nyc3.cdn.digitaloceanspaces.com/assets/home_page_logo.webp" class="home-page-logo">
  </div>
</template>

<script>
export default {
  name: 'IndexPage',
  layout: 'radbytes',
}
</script>

<style>
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(90vh - 120px); /* Full viewport height */
    min-width: 100vw; /* Full viewport width */
  }

  .home-page-logo {
    object-fit: contain;
    width: 37%; /* Adjust this value as needed */
    height: auto;
    margin-top:-110px;
  }
</style>
