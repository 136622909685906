import Vue from 'vue'; 
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    imageId: '',  // Initialize with an empty string or a default URL if you prefer
    activeTool: '',
    examResults: null,
    paused: false,
    firebaseUserId: null,
    emailVerificationRequired: false,
    userEmail: null,
    gradedAnswers: {} 
  },
  mutations: {
    setUserEmail(state, email) {
      state.userEmail = email;
    },
    setPaused(state, newVal) {
      state.paused = newVal;
    },
    setImageId(state, newImageId) {
      state.imageId = newImageId;  // Mutation to update the imageId
    },
    setActiveTool(state, newActiveTool) {
      state.activeTool = newActiveTool;
    },
    setExamResults(state, results) {
      if (results && typeof results === 'object' && !Array.isArray(results)) {
          const updatedResults = Object.keys(results).reduce((acc, key) => {
              const result = results[key];
              acc[key] = {
                  ...result,
                  correctAnswers: Array.isArray(result.correctAnswers) ? result.correctAnswers : (result.correctAnswers ? result.correctAnswers.split(', ') : [])
              };
              return acc;
          }, {});
          state.examResults = updatedResults;
      } else {
          console.error('Invalid input for setExamResults, expected an object:', results);
          state.examResults = {}; // Set to default state if input is invalid
      }
    },
    setFirebaseUserId(state, userId) {  // New mutation
      state.firebaseUserId = userId;
    },

    setEmailVerificationRequired(state, boolValue) {
      state.emailVerificationRequired = boolValue;
    },

    setGradedAnswers(state, gradedAnswers) {
      state.gradedAnswers = gradedAnswers;
    }

  },
  actions: {
    updateUserEmail({ commit }, email) {
      commit('setUserEmail', email);
    },
    updateImageId({ commit }, newImageId) {
      commit('setImageId', newImageId);  // Action to handle the updating process
    },
    updateActiveTool({ commit }, newActiveTool) {
      commit('setActiveTool', newActiveTool);
    },
    updateExamResults({ commit }, results) {
      commit('setExamResults', results);  // Commit the correct mutation with the results
    },
    updatePaused({ commit }, val) {
      commit('setPaused', val);
    },
    updateEmailVerificationRequired({ commit }, boolValue) {
      commit("setEmailVerificationRequired", boolValue);
    },
    updateFirebaseUserId({ commit }, userId) {  // New action
      commit('setFirebaseUserId', userId);
    },
    updateGradedAnswers({ commit }, gradedAnswers) {
      commit('setGradedAnswers', gradedAnswers);
    }
  },
  getters: {
    imageId: state => state.imageId,
    activeTool: state => state.activeTool,
    examResults: state => state.examResults || {},
    paused: state => state.paused,
    firebaseUserId: state => state.firebaseUserId,
    emailVerificationRequired: state => state.emailVerificationRequired,
    gradedAnswers: state => state.gradedAnswers,
    userEmail: state => state.userEmail
  }
});

export default store;
