import axios from 'axios';

// const API_URL = 'http://localhost:8000';
const API_URL = 'https://radbytes.org/api';

async function fetchExamQuestions(examNumber) {
    const url = `${API_URL}/get-exam-questions/${examNumber}`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const questionsData = await response.json();
      const allJpegs = [];
  
      const questions = questionsData.map(question => ({
        title: "Question " + question.Question,
        question: question.Question,
        isFlagged: false,
        answered: false,
        isAbnormal: question.Normality === "Abnormal",
        abnormalSelected: false,
        correctAnswers: question.Answers,
        providedReason: "",
        jpegs: question.jpeg_urls
      }));
  
      // Collect all jpeg URLs from each question
      questions.forEach(question => {
        allJpegs.push(...question.jpegs);
      });
  
      // Return both the questions and all Jpegs to manage in the component
      return { questions, allJpegs };
    } catch (error) {
      console.error('Failed to fetch exam questions:', error);
      return { questions: [], allJpegs: [] }; // Return empty arrays on error
    }
}



async function createCheckoutSession(firebaseUserID, packageType) {
    const url = `${API_URL}/create-checkout-session`;
    try {
        const payload = {
            "firebase_user_id": firebaseUserID,
            "package_type": packageType
        }
        const response = await axios.post(url, payload);
        return response.data.url;
    } catch (error) {
        console.error('Failed to create checkout session: ', error);
        return "";
    }
}




async function submitUserToDB(firebaseUserID, email) {
    const url = `${API_URL}/add_user_to_db`;
    try {
        const response = await axios.post(url, {
            firebase_user_id: firebaseUserID,
            email: email
        });
        if (response.data.status === 'User already exists') {
            console.log('User already in database');
            return { success: false, message: 'User already in database' };
        } else {
            console.log('User added to database');
            return { success: true, message: 'User added to database' };
        }
    } catch (error) {
        console.error('Failed to submit user to DB:', error);
        throw new Error("Failed to submit user: " + error.message);
    }
}

async function addUserEmail(firebaseUserID, email) {
    const url = `${API_URL}/add-user-email`;
    try {
        const response = await axios.put(url, {
            firebase_user_id: firebaseUserID,
            email: email
        });
        if (response.data.status === 'No such user exists') {
            console.log('No such user exists');
            return { success: false, message: 'No such user exists' };
        } else {
            console.log('User added to database');
            return { success: true, message: 'User email updated' };
        }
    } catch (error) {
        console.error('Failed to update user email:', error);
        throw new Error("Failed to update user email: " + error.message);
    }
}

async function gradeAnswers(payload, results) {
    const url = `${API_URL}/check_answers`;
    try {
        const response = await axios.post(url, payload);
        response.data.results.forEach(aiResult => {
            results[aiResult.question]["correct"] = aiResult.result;
        });
        return results;
    } catch (error) {
        console.error("Error checking answers with AI:", error);
        throw error; // Rethrowing the error to be handled by the caller
    }
}



async function saveAnswer(firebaseUserID, examPackage, questionNumber, userAnswer) {
    const url = `${API_URL}/save_answer`;

    try {
        let payload = {
            "firebase_user_id": firebaseUserID,
            "exam_package": examPackage,
            "question": questionNumber,
            "user_answer": userAnswer,
            "correct_answers": []
        }
        const response = await axios.post(url, payload);
        console.log("save response ", response);
    } catch (error) {
        console.error(error);
    }
}


async function overrideQuestion(firebaseUserID, examPackage, questionNumber, userAnswer, setToCorrect) {
    let url = `${API_URL}/mark_answer_as_correct`;
    if(!setToCorrect) {
        url = `${API_URL}/mark_answer_as_incorrect`;
    } 
    
    try {
        let payload = {
            "firebase_user_id": firebaseUserID,
            "exam_package": examPackage,
            "question": questionNumber,
            "user_answer": userAnswer,
            "correct_answers": []
        }
        const response = await axios.post(url, payload);
        console.log("save response ", response);
    } catch (error) {
        console.error(error);
    }
}

 
async function startNewExam(firebaseUserID, examPackage) {
    const payload = {
        "user_id": firebaseUserID,
        "exam_package": examPackage,
        "status": "IN_PROGRESS",
        "score": 0
    }
    await axios.post(`${API_URL}/create_exam_undertaken_instance`, payload)
        .then(response => {
            console.log("exam undertaken ", response);
        })
        .catch(error => {
            console.error("Error creating new exam:", error);
            // Handle errors, maybe update the UI to show an error message
        });
}


async function getUserAnswers(firebaseUserID, examPackage) {
    const url = `${API_URL}/user-answers/${examPackage}/${firebaseUserID}`;
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const responseData = await response.json(); // Parse JSON from the response
        if (responseData.status === 'success') {
            console.log("ANSWERS FROM DB", responseData.data);
            return responseData.data; // Ensure to return the actual data array
        } else {
            throw new Error('Failed to retrieve valid data from server');
        }
    } catch (error) {
        console.error('Failed to fetch exam answers:', error);
        return []; // Return an empty array if there are errors
    }
}


async function submitScores(payload) {
    const url = `${API_URL}/submit_scores`;
    axios.post(url, payload)
        .then(response => {
            console.log("scores submitted ", response);
        })
        .catch(error => {
            console.error("Error submitting scores:", error);
    });
}


async function userIsAdmin(payload) {
    const url = `${API_URL}/user-is-admin`;
    try {
        const response = await axios.post(url, payload); // Assuming payload should be an object with an email property
        return response.data.is_admin; // Accessing directly the is_admin property from response.data
    } catch (error) {
        console.error("Error checking if user is admin: ", error);
        return false;
    }
}


async function getUserMessages(payload) {
    const url = `${API_URL}/get-messages`;
    try {
      const response = await axios.post(url, payload);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected response status:", response.status);
        return null; // Handle non-200 responses if necessary
      }
    } catch (error) {
      console.error("Failed to get messages ", error);
      return null; // Ensure null is returned to handle in calling function
    }
}


async function deleteUserMessage(payload) {
    const url = `${API_URL}/delete-message`;
    try {
        const response = await axios.post(url, payload);
        if (response.status === 200) {
          return response.data;
        } else {
          console.error("Unexpected response status:", response.status);
          return null; // Handle non-200 responses if necessary
        }
    } catch (error) {
        console.error("Failed to delete message ", error);
        return null; // Ensure null is returned to handle in calling function
    }
}


async function markMessageAsResponded(payload) {
    const url = `${API_URL}/mark-message-as-responded`;
    try {
        const response = await axios.post(url, payload);
        if (response.status === 200) {
          return response.data;
        } else {
          console.error("Unexpected response status:", response.status);
          return null; // Handle non-200 responses if necessary
        }
    } catch (error) {
        console.error("Failed to delete message ", error);
        return null; // Ensure null is returned to handle in calling function
    }  
}


async function sendMessage(payload) {
    const url = `${API_URL}/submit-message`;
    try {
        const response = await axios.post(url, payload);
        if (response.status === 200) {
          return response.data;
        } else {
          console.error("Unexpected response status:", response.status);
          return null; // Handle non-200 responses if necessary
        }
    } catch (error) {
        console.error("Failed to delete message ", error);
        return null; // Ensure null is returned to handle in calling function
    }  
}
  

async function getActivePromos(email) {
    const url = `${API_URL}/get-active-promos/${email}`;
    try {
        const response = await fetch(url);
        if(!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const responseData = await response.json();
        return responseData
    } catch (error){
        console.error('Failed to obtain active promos: ', error);
        return [];
    }
}



async function getActiveSubscriptions(firebaseUserID) {
    const url = `${API_URL}/get-active-subscriptions/${firebaseUserID}`;
    try {
        const response = await fetch(url);
        if(!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const responseData = await response.json();
        return responseData
    } catch (error){
        console.error('Failed to obtain active subscriptions: ', error);
        return [];
    }
}

async function getActiveSubscriptionTypes(firebaseUserID) {
    const url = `${API_URL}/get-active-subscription-types/${firebaseUserID}`;
    try {
        const response = await fetch(url);
        if(!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const responseData = await response.json();
        return responseData
    } catch (error){
        console.error('Failed to obtain active subscriptions: ', error);
        return [];
    }
}





async function getExamReview(examPackage, firebaseUserID) {
    const url = `${API_URL}/exam-review/${examPackage}/${firebaseUserID}`;
    try {
        const response = await fetch(url);
        if(!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const responseData = await response.json();
        return responseData
    } catch (error){
        console.error('Failed to obtain active subscriptions: ', error);
        return [];
    }
}


async function getEduImages(regionOfBody) {
    const url = `${API_URL}/get-edu-images/${regionOfBody}`;
    try {
        const response = await fetch(url);
        if(!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const responseData = await response.json();
        return responseData
    } catch(error) {
        console.error('Failed to get images');
        return [];
    }
}

async function getEduImageRegions() {
    const url = `${API_URL}/get-edu-image-regions`;
    try {
        const response = await fetch(url);
        if(!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const responseData = await response.json();
        return responseData
    } catch(error) {
        console.error('Failed to get regions');
        return [];
    }
}

export { 
    fetchExamQuestions, 
    submitUserToDB, 
    addUserEmail,
    gradeAnswers, 
    saveAnswer, 
    startNewExam,
    getUserAnswers,
    submitScores,
    getActivePromos,
    getActiveSubscriptions,
    getActiveSubscriptionTypes,
    createCheckoutSession,
    getExamReview,
    overrideQuestion,
    userIsAdmin,
    getUserMessages,
    deleteUserMessage,
    markMessageAsResponded,
    sendMessage,
    getEduImages,
    getEduImageRegions
}
