<template>
    <div class="countdown-timer flex-end">{{ formattedCountdown }}</div>
</template>


<script>

    export default {
        data() {
            return {
                countdown: 35 * 60,
                formattedCountdown: '',
            }
        },
        mounted() {
            this.timerInterval = setInterval(() => {
            if (this.countdown > 0) {
                if(!this.$store.state.paused) {
                    this.countdown--;
                    this.formattedCountdown = this.formatCountdown(this.countdown);
                }
            } else {
                clearInterval(this.timerInterval);
            }
            }, 1000);
        },
        methods: {
            formatCountdown(seconds) {
                const minutes = Math.floor(seconds / 60);
                const remainingSeconds = seconds % 60;
                return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
            },
        },
    }

</script>


<style></style>