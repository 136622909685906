<template>

    <div class="dicom-viewer" id="edu-dcm-images-viewer" ref="dicomViewer" style="max-width: 100%; position: relative;">
        <div id="dicomImage" ref="cornerstoneElement" style="width: 100%; height: 100%; position: absolute;">
        </div>
    </div>

</template>




<script>
    import $ from 'jquery';
    export default {
        data() {
            return {
                element: null,
            }
        },
        props: {
            height: {
                type: Number,
                default: 85
            },
            squared: {
                type: Boolean,
                default: false
            },
        },
        mounted() {
            $('#edu-dcm-images-viewer').css('max-height', this.height.toString()+'vh');
            if(this.squared) {
                var width = window.innerHeight * (this.height/80);
                
                $('#edu-dcm-images-viewer').css('width', width.toString()+'px');
                $('#edu-dcm-images-viewer').css('margin', '0 auto');
            }

            this.$nextTick(()=>{
                this.$store.dispatch(
                    "updateImageId", 'https://rapids.nyc3.cdn.digitaloceanspaces.com/loading.jpeg'
                );
                this.initializeCornerstone();
            })
        },
 
        watch: {
            // Watch a Vuex state by defining a function that returns the value of the state
            'imageIdFromStore': {
                // Handler function for when 'imageIdFromStore' changes
                handler(newImageId) {
                    if(typeof(newImageId) == undefined) {
                        return;
                    }
                    this.loadAndViewImage(newImageId);
                    this.$cornerstone.reset(this.element);
                    setTimeout(() => {
                        this.$cornerstone.reset(this.element);
                    }, 150);
                },
                // We use this function to return the Vuex state
                immediate: false,  // Optionally, run the handler immediately to react to the initial value
                deep: true  // Use this if you need to deeply watch for changes within objects or arrays
            },


            'activeToolFromStore': {

                

                handler(newActiveTool) {
                    // Check if newActiveTool is undefined and return early if true
                    if (typeof newActiveTool === undefined) {
                        return;
                    }

                    this.deactivateAllTools();

                    // Switch statement to handle different cases based on the newActiveTool value
                    switch (newActiveTool) {
                        case 'Pan':
                            this.$refs.dicomViewer.classList.add('cursor-panning');
                            this.$cornerstoneTools.setToolActive('Pan', { mouseButtonMask: 1 });
                            break;


                        case 'Wwwc':
                            this.$refs.dicomViewer.classList.add('cursor-contrast');
                            this.$cornerstoneTools.setToolActive('Wwwc', { mouseButtonMask: 1 });
                            break;


                        case 'Zoom':
                            this.$refs.dicomViewer.classList.add('cursor-zoom');
                            this.$cornerstoneTools.setToolActive('Zoom', { mouseButtonMask: 1 });
                            break;


                        case 'Rotate':
                            this.$refs.dicomViewer.classList.add('cursor-rotate');
                            this.$cornerstoneTools.setToolActive('Rotate', { mouseButtonMask: 1 });
                            break;

                        case 'Reset':
                            this.$cornerstone.reset(this.element);
                            break;




                        default:
                            // Code to execute if none of the above cases match
                            console.log('Unknown tool: ', newActiveTool);
                    }
                },
                deep: true  // Optional: Use if newActiveTool can be an object needing deep watching
            }
            
        },

        computed: {
            imageIdFromStore() {
                return this.$store.state.imageId;
            },
            activeToolFromStore() {
                return this.$store.state.activeTool;
            }
        },

        methods: {
            deactivateAllTools() {
                this.$refs.dicomViewer.classList.remove('cursor-zoom');
                this.$refs.dicomViewer.classList.remove('cursor-contrast');
                this.$refs.dicomViewer.classList.remove('cursor-panning');
                this.$refs.dicomViewer.classList.remove('cursor-rotate');

                this.$cornerstoneTools.setToolPassive('Pan');
                this.$cornerstoneTools.setToolPassive('Wwwc');
                this.$cornerstoneTools.setToolPassive('Zoom');
                this.$cornerstoneTools.setToolPassive('Rotate');
            },
            initializeCornerstone() {

                if (!this.$cornerstone || !this.$cornerstoneWADOImageLoader || !this.$dicomParser) {
                    console.error("Cornerstone or one of its dependencies is undefined");
                    return;
                }

                this.$cornerstoneTools.external.cornerstone = this.$cornerstone;
                this.$cornerstoneTools.init();

                this.element = this.$refs.cornerstoneElement;
                this.$cornerstone.element = this.element;
                this.$cornerstone.enable(this.element);

                // this.$cornerstoneWADOImageLoader.external.cornerstone = this.$cornerstone;
                // this.$cornerstoneWADOImageLoader.external.dicomParser = this.$dicomParser;
 

                this.$cornerstoneWebImageLoader.external.cornerstone = this.$cornerstone;


                // this.$cornerstoneDICOMImageLoader.external.dicomParser = this.$dicomParser;
                // this.$cornerstoneDICOMImageLoader.external.cornerstone = this.$cornerstone;
                

                // Register the wadouri prefix with the WADO Image Loader
                // this.$cornerstone.registerImageLoader('wadouri:', this.$cornerstoneWADOImageLoader.loadImage);
                this.$cornerstone.registerImageLoader('https', this.$cornerstoneWebImageLoader.loadImage);

                // Load a DICOM image
                this.loadAndViewImage(this.$store.state.imageId);
                this.updateViewerSize();

                const WwwcTool = this.$cornerstoneTools.WwwcTool;
                this.$cornerstoneTools.addTool(WwwcTool);
                const PanTool = this.$cornerstoneTools.PanTool;
                this.$cornerstoneTools.addTool(PanTool);
                const ZoomTool = this.$cornerstoneTools.ZoomTool;
                this.$cornerstoneTools.addTool(ZoomTool);
                const RotateTool = this.$cornerstoneTools.RotateTool;
                this.$cornerstoneTools.addTool(RotateTool);
            },

            updateViewerSize() {
                const viewer = this.$refs.dicomViewer;
                if (viewer) {
                    const width = viewer.offsetWidth;
                    viewer.style.height = `${width * 1}px`; // Height is 50% of the width
                    this.$cornerstone.resize(this.element, true); // Resize the Cornerstone viewport
                }
            },

            loadAndViewImage(imageId) {
                // imageId = `wadouri:${imageId}`;
     
                const element = document.getElementById('dicomImage');
                let vm = this;
                this.$cornerstone.loadImage(imageId).then(function(image) {
                    const viewport = vm.$cornerstone.getDefaultViewportForImage(element, image);
                    vm.$cornerstone.displayImage(element, image, viewport);

                }, function(err) {
                    console.log(err);
                });
                // 4.13.2
            }

        }
    }

</script>




<style scoped>

    .dicom-option {
      color: rgba(250,250,250,0.8);
    }

    .dicom-option:hover {
      cursor: pointer;
      font-size:31px;
      margin-right:0px;
      margin-left:-3px;
      margin-top: -3px;
      color: white;
      transition: all 0.2s;
    }

    .dicom-viewer {
      border-radius: 7px!important;
      overflow:hidden;
    }

</style>