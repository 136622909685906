<template>
    <div class="toolbar flex-space-between">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span id="panToolButton" class="dicom-option" style="margin-right:20px" v-bind="attrs" v-on="on">
            <font-awesome-icon :class="{ 'active': activeTool === 'pan' }" :icon="['fa', 'arrows-up-down-left-right']" />
          </span>
        </template>
        <span>Pan [ P ]</span>
      </v-tooltip>
  
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span id="wwwcToolButton" class="dicom-option" style="margin-right:20px" v-bind="attrs" v-on="on">
            <font-awesome-icon :class="{ 'active': activeTool === 'wwwc' }" :icon="['fa', 'circle-half-stroke']" />
          </span>
        </template>
        <span>Window [ W ]</span>
      </v-tooltip>
  
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span id="zoomToolButton" class="dicom-option" style="margin-right:20px" v-bind="attrs" v-on="on">
            <font-awesome-icon :class="{ 'active': activeTool === 'zoom' }" :icon="['fa', 'magnifying-glass']" />
          </span>
        </template>
        <span>Zoom [ Z ]</span>
      </v-tooltip>
  
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span id="rotateToolButton" class="dicom-option" style="margin-right:20px" v-bind="attrs" v-on="on">
            <font-awesome-icon :class="{ 'active': activeTool === 'rotate' }" :icon="['fa', 'rotate-right']" />
          </span>
        </template>
        <span>Rotate [ R ]</span>
      </v-tooltip>
  
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span id="resetButton" class="dicom-option" v-bind="attrs" v-on="on">
            <font-awesome-icon :icon="['fa', 'recycle']" />
          </span>
        </template>
        <span>Reset [ F ]</span>
      </v-tooltip>
    </div>
  </template>
  






<script>

    export default {
        data() {
            return {
                activeTool: null,
            }
        },
        mounted: function() {

            this.$nextTick(()=>{
                document.getElementById('panToolButton').addEventListener('click', () => {
                    this.selectPanningTool();
                });

                document.getElementById('wwwcToolButton').addEventListener('click', () => {
                    this.selectWwwCTool();
                });

                document.getElementById('zoomToolButton').addEventListener('click', () => {
                    this.selectZoomTool();
                });

                document.getElementById('rotateToolButton').addEventListener('click', () => {
                    this.selectRotateTool();
                });

                document.getElementById('resetButton').addEventListener('click', () => {
                    this.selectResetTool();
                });

                document.addEventListener('keydown', this.handleKeyDown.bind(this));
            })



        },
        methods: {

            selectPanningTool() {
                this.$store.dispatch(
                    "updateActiveTool", "Pan"
                );
                this.activeTool = "pan";
            },

            selectWwwCTool() {
                this.$store.dispatch(
                    "updateActiveTool", "Wwwc"
                );
                this.activeTool = "wwwc";
            },

            selectZoomTool() {
                this.$store.dispatch(
                    "updateActiveTool", "Zoom"
                );
                this.activeTool = "zoom";
            },

            selectRotateTool() {
                this.$store.dispatch(
                    "updateActiveTool", "Rotate"
                );
                this.activeTool = "rotate";
            },

            selectResetTool() {

                let prevTool = this.$store.state.activeTool;
                this.$store.dispatch(
                    "updateActiveTool", "Reset"
                );
                this.activeTool = null;
                setTimeout(()=>{
                    this.$store.dispatch(
                        "updateActiveTool", prevTool
                    ); 
                }, 100)

            },

            handleKeyDown(event) {

                const activeElement = document.activeElement;

                if(activeElement.id === 'answer-textarea') {
                    return;
                }

                if (event.key.toLowerCase() === "f") {
                    this.selectResetTool();
                }

                if (event.key.toLowerCase() === 'p') { 
              
                    this.selectPanningTool();
                }

                if (event.key.toLowerCase() === 'w') {
                    this.selectWwwCTool();
                }

                if (event.key.toLowerCase() === 'z') {
                    this.selectZoomTool();
                }

                if (event.key.toLowerCase() === 'r') {
                    this.selectRotateTool();
                }
            
            },



        }
    }

</script>



<style scoped>

    .toolbar {
        height: 50px;
        flex:1;
        margin-bottom:10px;
        border-radius: 3px;

        
    }

    .dicom-option {
        color: rgba(250,250,250,0.8);
    }

    .dicom-option:hover {
        cursor: pointer;
        font-size:31px;
        margin-right:0px;
        margin-left:-3px;
        margin-top: -3px;
        color: white;
        transition: all 0.2s;
    }

    .active {
        font-size:31px;
        margin-right:0px;
        margin-left:-3px;
        margin-top: -3px;
        color: white;
    }

</style>