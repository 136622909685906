<template>
    <v-container fluid style="margin-top:10px; width:91vw">
            <v-navigation-drawer class="questions-side-nav" app v-model="drawer" style="z-index:1000;">
              <v-list class="questions-list">
                <v-list-item v-for="(item, index) in imageRegions" :key="index" class="question-list-item">
                  <v-list-item-content>
                    <div class="flex-space-between">

                      <v-list-item-title
                        class="question-selection"
                        :id="item === regionOfBody ? 'selected-question' : null"
                        @click="getEduImagesFromAPI(item)"
                      >
                        {{ item }}
                      </v-list-item-title>
                    </div>
                  </v-list-item-content> 
                </v-list-item>
              </v-list>
            </v-navigation-drawer>

            <v-row>

                <v-col cols="1">
                  <v-btn class="view-questions-btn " style="margin-left:-65px; background-color: rgba(0,0,0,0); margin-bottom:-100px; padding-top:25px!important; padding-bottom:25px!important; padding-right:20px!important" @click.stop="drawer = !drawer">
                    <span v-if="!drawer"><font-awesome-icon :icon="['fas', 'bars']" /></span>
                    <span v-else><font-awesome-icon :icon="['fas', 'x']" /></span>
                  </v-btn>
                </v-col>
                <v-col cols="3"></v-col>

                <v-col cols="4" class="flex-space-between" style="width:100%">
                    <ToolBar style="flex:3; font-size:18px; height:40px; margin-top:-10px"/>
                </v-col>

                <v-col cols="4" class="flex-space-between">

                  <span></span>
                  <span>
                    <v-btn class="view-questions-btn " style="background-color: rgba(0,0,0,0); margin-bottom:-100px; padding-top:25px!important; padding-bottom:25px!important; padding-right:20px!important;" @click="backToHomePage()">
                      <span><font-awesome-icon :icon="['fas', 'x']" /></span>
                    </v-btn>
                  </span>
                </v-col>
                
            </v-row>

            <!-- DICOM viewer row -->
            <v-row style="max-height: 95vh;">

                <v-col cols="1" style="z-index:999;">
                  <div style="margin-left:-50px!important">
                    <h1 style="font-weight:bolder">{{ selectedBodyPart }}</h1>
                    <div style="height:60px"></div>
                    <ul class="image-options">
                      <li v-for="(imageOption, index) in imageOptions" :key="imageOption.id" class="image-option" @click="loadImage(index)" :id="`img-option-${index}`">
                        {{ imageOption.img_type }}
                      </li>
                    </ul>
                  </div>

                </v-col>
                <!-- DICOM Images -->
         

                <!-- DICOM Viewer -->
                <v-col cols="10">

                  <DicomViewer :squared="true" :height=92 style="margin-top:-20px"/>

                </v-col>

                <v-col cols="1">
                  
                  <div class="flex-vertical-space-between">

                    <!-- NOT IN REVIEW MODE -->



                    <!-- IN REVIEW MODE -->



                  </div>

                </v-col>
            </v-row>
          
    </v-container>
</template>


<script>

import { 
  getEduImages,
  getEduImageRegions
} from '@/api.js';

import $ from 'jquery';

import DicomViewer from "../components/DicomViewer.vue";
import ToolBar from "../components/ToolBar.vue";

export default {

  name: 'RapidExam',

  components: {
    DicomViewer,
    ToolBar
  },

  data() {
    return {
      drawer: false,
      gradedAnswers: [],
      selectedBodyPart: "", 
      imageOptions: [],
      imageRegions: [],
      regionOfBody: undefined,
      currentImage: undefined
    };
  },

  watch: {

  },

  async mounted() {

    console.log("regionOfBody ", this.$route.query.region);
    let regionOfBody = this.$route.query.region;
    if(regionOfBody === undefined || regionOfBody === "" || regionOfBody == null) {
      regionOfBody = "ABDOMEN";
      this.$router.push({ path: this.$route.path, query: { region: regionOfBody } }).catch(err => {
        if (err.name !== 'NavigationDuplicated' && !err.message.includes('Avoided redundant navigation to current location')) {
          console.error(err);
        }
      });
    }

    this.regionOfBody = regionOfBody.toUpperCase();
    
    var regionsData = await getEduImageRegions();
    this.imageRegions = regionsData.regions;
    await this.getEduImagesFromAPI(this.regionOfBody);
    this.loadImage(0);
    this.currentImage = this.imageOptions[0];
    this.updateSelectedOption(0);


    $('#edu-dcm-images-viewer').css('margin-top', '-20px');

    // var height = window.innerHeight * 0.8;
    // $('#edu-dcm-images-viewer').css('width', height.toString()+'px');
    // $('#edu-dcm-images-viewer').css('margin', '0 auto');
  },

  beforeDestroy() {

  },

  methods: {

    backToHomePage() {
      window.location.href = "/";
    },

    updateSelectedOption(imageOptionIndex) {
   
      const previouslySelected = document.querySelector('.selected-option');
      if (previouslySelected) {
        previouslySelected.classList.remove('selected-option');
      }

      setTimeout(() => {
        const element = document.getElementById(`img-option-${imageOptionIndex}`);
        if (element) {
            element.classList.add('selected-option');
        }
      }, 250);
    
      const element = document.getElementById(`img-option-${imageOptionIndex}`);
      if (element) {
        element.classList.add('selected-option');
      }
    },

    async getEduImagesFromAPI(regionOfBody) {
      this.$router.push({ path: this.$route.path, query: { region: regionOfBody } }).catch(err => {
        if (err.name !== 'NavigationDuplicated' && !err.message.includes('Avoided redundant navigation to current location')) {
          console.error(err);
        }
      });
      this.drawer = false;
      this.regionOfBody = regionOfBody;
      const data = await getEduImages(regionOfBody);
      this.imageOptions = data.edu_images;
      this.selectedBodyPart = regionOfBody.toUpperCase();
      this.loadImage(0);
    },


    loadImage(imageOptionIndex) {
      console.log("INDEX: " + imageOptionIndex + ". URL: " + this.imageOptions[imageOptionIndex].img_url)
      this.$store.dispatch(
        "updateImageId", this.imageOptions[imageOptionIndex].img_url
      );
      this.updateSelectedOption(imageOptionIndex);
    }

    
  },
  computed: {

  }
}
</script>





<style scoped>


    #selected-question {
      font-size: 18px!important;
      color: rgba(255,255,255,0.95)!important;
      font-weight: bolder!important;
    }

    .question-selection:hover {
      cursor: pointer;
      font-size: 18px!important;
      color: rgba(255,255,255,0.95);
    }

    .question-selection {
      color: rgba(255,255,255,0.75);
      font-size: 16px!important;
    }

    .questions-list {
      padding: 5px 10px 5px 10px!important;
    }

    .questions-side-nav {
      background-color: rgb(12,12,12)!important;
      border-right: 1px solid rgba(0,0,0,1);
      width:200px!important;
    }

    .image-option {
      list-style-type: none;
      margin-left:-20px;
      padding:25px 0 0 0;
      font-size:20px;
      color: rgba(255,255,255,0.75);
    }

    .image-option:hover {
      cursor: pointer;
      color:white!important;
      font-weight: bolder;
      color: rgba(255,255,255,0.95);
    }

    .selected-option {
      color:white!important;
      font-weight: bolder;
    }

    .flex-end {
      display: flex;
      justify-content: end;
    }


</style>
