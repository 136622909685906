<template>
  <v-container class="extra-padded-container">
    <div class="rapids-outer-container">
      <div class="rapids-inner-container">
        <div class="rapids-h1"><h1>Rapids</h1></div>

        <p class="rapids-content lg">
          <!-- At <b class="mr ml">RadBytes</b> we are commited to transform the way we learn <b style="ml">Radiology</b>.<br>
          By combining <b>technology</b>, <b>art</b> and <b>design</b>, we strive to create an immersive<br>
          <span class="mr" style="display:inline-block;"></span>learning experience that captivates you, fueling your passion for <b>Radiology</b>. -->
          Peer reviewed <b>Rapid</b> packets displayed on an image viewer.<br>
          Simulates the <b>FRCR 2B</b> Rapid Reporting session exam format.<br><br>
          Try out the <b>Free</b> version below.
        </p>

        <p class="rapids-content sm mb-5">
          Peer reviewed <b>Rapid</b> packets displayed on an image viewer.
          Simulates the <b>FRCR 2B</b> Rapid Reporting session exam format.<br><br>
          Try out the <b>Free</b> version below.
        </p>


        <div class="flex-space-between purchase-buttons-container"> 
        
          <!-- "/rapid-exam?packet=1&newAttempt=true" -->
            <div>
           
                <v-btn class="mt-1 mb-3 rad-btn" @click="startFreeExam()">
                    Free
                </v-btn>
          
                <div class="package-description">
                    <b>One</b> Rapid<br>
                    <font-awesome-icon class="" :icon="['fas', 'infinity']" style="font-size:22px;"/>
                </div>
            </div>


            <div>
                <a :href="checkoutBasicURL">
                  <v-btn  class="mt-1 mb-3 rad-btn">
                    £10
                  </v-btn>
                </a>
                <div class="package-description">
                    <b>Ten</b> Rapids<br>
                    One Month
                </div>
            </div>


            <div>
                <v-btn class="mt-1 mb-3 rad-btn">
                  £20
                </v-btn>
                <div class="package-description">
                    More Content<br>
                    Coming Soon
                </div>
            </div>

        </div>


        <h2 style="margin-top:85px; margin-bottom:45px">How it Works</h2>
        <p class="rapids-content lg">
            In each rapid packet you can expect the following content.<br>
            <b>30</b> questions with a mixture of <b>normal</b> and <b>abnormal</b> films.<br>
            <b>35</b> minutes to answer these questions on our <b>image viewer</b>.<br><br>
            If abnormal there may be a <b>single</b> or <b>multiple</b> related pathologies.<br>
            Anatomical variants and simple degenerative are deemed normal.<br><br>
            Answers are checked by our AI algorithms saving you time.<br>
            Review your answers and track your <b>progress</b> over time.
        </p>

        <p class="rapids-content sm mb-5">
            In each rapid packet you can expect the following content.
            <b>30</b> questions with a mixture of <b>normal</b> and <b>abnormal</b> films.
            <b>35</b> minutes to answer these questions on our <b>image viewer</b>.<br><br>
            If abnormal there may be a <b>single</b> or <b>multiple</b> related pathologies.
            Anatomical variants and simple degenerative are deemed normal.<br><br>
            Answers are checked by our AI algorithms saving you time.
            Review your answers and track your <b>progress</b> over time.
        </p>



      </div>



    </div>
  </v-container>
</template>

<script>

import { startNewExam, createCheckoutSession, getActiveSubscriptionTypes } from '@/api.js';

export default {
  name: 'RapidsPage',
  layout: 'radbytes',
  data() {
    return {
      checkoutBasicURL: "/login",
      checkoutProURL: "/login"
    }
  },

  watch: {
    firebaseUserId: {
      handler(newValue) {
        if(newValue == null) {
          this.checkoutBasicURL = "/login";
          this.checkoutProURL = "/login";
        }
      },
      immediate: true // Optional: Trigger the handler immediately with the current value on first render
    }
  },

  computed: {
    firebaseUserId() {
      return this.$store.state.firebaseUserId;
    }
  },

  async mounted() {


    if(this.$store.state.firebaseUserId === null) {
      return;
    }

    const emailVerificationRequired = this.$store.state.emailVerificationRequired;

    if(emailVerificationRequired) {
      this.checkoutBasicURL = "/verify-email";
      this.checkoutProURL = "/verify-email";
      return;
    } else {
      

      const activeSubscriptions = await getActiveSubscriptionTypes(this.$store.state.firebaseUserId);
    
      if(!activeSubscriptions.includes("basic")) {
        const basicURL = await createCheckoutSession(this.$store.state.firebaseUserId, "basic");
        this.checkoutBasicURL = basicURL;
      } else {
        this.checkoutBasicURL = "/my-rapids";
      }

      if(!activeSubscriptions.includes("pro")) {
        const proURL = await createCheckoutSession(this.$store.state.firebaseUserId, "pro");
        this.checkoutProURL = proURL;
      } else {
        this.checkoutProURL = "/my-rapids";
      }

    }




  },
  methods: {
    async startFreeExam() {
      this.$notify({
          group: 'dark',
          title: 'Preparing Exam...',
          text: '',
          duration: 5000
      });
      await startNewExam(this.$store.state.firebaseUserId, 1);
      window.location.href="/rapid-exam?packet=1&newAttempt=true";
    }
  }
}
</script>

<style scoped>

.purchase-buttons-container {
    max-width:750px;
    margin-top:70px;
}

.package-description {
    margin-top:10px;
    font-size:16px!important;
    text-align: center;
}

.rad-btn {
    min-width:100px!important;
}

h1, h2 {
    display:inline-block;
}

h2 {
    margin-right:14px;
    font-size: 38px;
}

h1 {
    font-size: 60px;
}

.sm {
  display:none;
}


.mr {
  margin-right: 11px;
}

.ml {
  margin-left: 14px;
}

.rapids-h1 {
  margin-bottom: 50px;
  text-align: left;
}



.rapids-content {
  margin-bottom: 50px!important;
}



.rapids-container {
  display: flex;
  justify-content: center;
}

.rapids-outer-container {
  display: flex;
  justify-content: center;
}

.rapids-inner-container {
  text-align: left; /* Ensure this is consistent and logical in your layout */
  align-items: center;
  /* Add a max-width if necessary to control the width of the inner container */
}




@media (max-width: 1263px) {
  .mr {
    margin-right: 0px;
  }

  .ml {
    margin-left: 0px;
  }

  .rapids-inner-container {
    text-align: left;
  }

  .rapids-h1 {
    text-align: left;
    margin-left:0px;
  }

  .sm {
    display:block;
  }

  .lg {
    display:none;
  }
}


</style>
