<template>
    <v-container class="mt-5">
      <!-- Row for the content -->
      <v-row>
        <v-col cols="12" sm="8" md="6" lg="4" class="mx-auto">
  
          <!-- Heading -->
          <h1 class="mb-4">Reset Your Password</h1>
  
          <!-- Subtext -->
          <div class="mb-5" style="font-size:18px;">Enter your new password below</div>
  
          <!-- Password Reset Form -->
          <v-form @submit.prevent="resetPassword">
            <v-text-field
              label="New Password"
              v-model="newPassword"
              type="password"
              required
              outlined
              class="mb-2"
            ></v-text-field>

            <v-text-field
              label="Confirm Password"
              v-model="confirmPassword"
              type="password"
              required
              outlined
              class="mb-2"
            ></v-text-field>
  
            <!-- Submit Button -->
            <v-btn type="submit" color="" block large>Reset Password</v-btn>
          </v-form>
  
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import { confirmPasswordReset } from 'firebase/auth';
import { auth } from '../firebaseConfig';

export default {
  data() {
    return {
      newPassword: '',
      confirmPassword: ''
    };
  },
  methods: {
    resetPassword() {
      if (this.newPassword !== this.confirmPassword) {
        this.$notify({
            group: 'dark',
            title: 'Error',
            text: 'Passwords do not match!',
            duration: 5000 // Set to 8000ms, adjust duration as necessary
        });
        return;
      }
      const oobCode = this.$route.query.oobCode; // Firebase oobCode from the URL
      if (!oobCode) {
        this.$notify({
            group: 'dark',
            title: 'Error',
            text: 'No reset code found. Please check the link or request a new one!',
            duration: 5000 // Set to 8000ms, adjust duration as necessary
        });
        return;
      }

      confirmPasswordReset(auth, oobCode, this.newPassword)
        .then(() => {
          this.$notify({
            group: 'dark',
            title: 'Success',
            text: 'Your password has been reset successfully. You can now log in with the new password!',
            duration: 10000 // Set to 8000ms, adjust duration as necessary
          });
          this.$router.push('/login'); // Redirect to login page
        })
        .catch(error => {
          console.error('Error resetting password:', error);
          this.$notify({
            group: 'dark',
            title: 'Error',
            text: 'Failed to reset password. Please try again!',
            duration: 5000 // Set to 8000ms, adjust duration as necessary
          });
        });
    }
  }
}
</script>
